import React, { useState } from 'react'
import styled from 'styled-components';
import { PiStudent } from "react-icons/pi";
import { RiSchoolLine } from "react-icons/ri";
import { PiUserRectangleThin } from "react-icons/pi";
import { weeklyData, monthlyData, yearlyData } from "../data/data.js"
import { Line } from 'react-chartjs-2';
import { CategoryScale, Chart } from "chart.js";
import { registerables } from 'chart.js';
import { LiaChalkboardTeacherSolid } from "react-icons/lia";
import { MdOutlinePlayLesson } from "react-icons/md";
import { IoTrendingUp } from "react-icons/io5";
import { GiReceiveMoney } from "react-icons/gi";


Chart.register(...registerables);
Chart.register(CategoryScale);

const Containerdiv = styled.div`
  background-color: rgba(111, 133, 147, 0.1);
  border-radius: 10px;

  padding: 40px;
  margin-top: 20px;
`;

const StyledDiv = styled.div`
  height: 100px;
  border-radius: 10px;
  display: flex;
  background-color: white;
  justify-content: space-evenly;
  align-items: center;
`;

const StyledLay = styled.div`
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Styledh5 = styled.h5`
  font-weight: 400;
  font-size: 17px;
  font-family: "Roboto Condensed", sans-serif;
  color: #b4b4b4;
`;

const Styledh4 = styled.h4`
  font-family: "Raleway";
  font-weight: 800;
  font-size: 24px;
  margin-left: 5px;
`;

const StyledButton = styled.button`
  background-color: transparent;
  color: #5d5d5d;
  font-weight: 600;
  font-size: 14px;
  font-family: Raleway;
  width: 250px;
  height: 45px;
  margin: 0 5px;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: rgba(111, 133, 147, 0.1);
    color: #064792 ;

  }
  &.active {
    background-color: rgba(111, 133, 147, 0.1);
      color: #064792 ;

  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;


const Statistics = () => {

    const [data, setData] = useState(yearlyData);
    const [activeButton, setActiveButton] = useState('yearly');
  
    const handleButtonClick = (dataType) => {
      setData(dataType);
      switch (dataType) {
        case weeklyData:
          setActiveButton('weekly');
          break;
        case monthlyData:
          setActiveButton('monthly');
          break;
        case yearlyData:
          setActiveButton('yearly');
          break;
        default:
          setActiveButton('yearly');
      }
    };
  
  
  return (
    <div>
          <div className='flex justify-between align-middle mx-3'>
            <Styledh4 style={{ color: "#34405e" }}>Kayıt özeti</Styledh4>
            <ButtonContainer className='w-1/2 float-end'>
              <StyledButton
                onClick={() => handleButtonClick(weeklyData)}
                className={activeButton === 'weekly' ? 'active' : ''}
              >
                Haftalık
              </StyledButton>
              <StyledButton
                onClick={() => handleButtonClick(monthlyData)}
                className={activeButton === 'monthly' ? 'active' : ''}
              >
                Aylık
              </StyledButton>
              <StyledButton
                onClick={() => handleButtonClick(yearlyData)}
                className={activeButton === 'yearly' ? 'active' : ''}
              >
                Yıllık
              </StyledButton>
            </ButtonContainer>
          </div>
          <Line
            data={data}
            options={{
              scales: {
                x: {
                  type: 'category'
                }
              }
            }}
          />
        </div>
  )
}

export default Statistics