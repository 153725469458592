import React, { useState } from 'react';
import styled from 'styled-components';
import { PiStudent } from "react-icons/pi";
import { RiSchoolLine } from "react-icons/ri";
import { weeklyData, monthlyData, yearlyData } from "../../admin/data/data.js"
import { Line } from 'react-chartjs-2';
import { CategoryScale, Chart } from "chart.js";
import { registerables } from 'chart.js';
import { LiaChalkboardTeacherSolid } from "react-icons/lia";
import { MdNotificationsActive } from "react-icons/md";
import { GiGraduateCap } from "react-icons/gi";

import { IoTrendingUp } from "react-icons/io5";
import Calendar from './Calendar.jsx';
import Statistics from './Statistics.jsx';

Chart.register(...registerables);
Chart.register(CategoryScale);

const Containerdiv = styled.div`
  box-shadow: none;
  background-color: rgba(111, 133, 147, 0.1);
  border-radius: 20px;
  padding: 40px;
  margin-top: 10px;
  margin-left: 20px;
`;

const StyledDiv = styled.div`
  height: 100px;
  border-radius: 10px;
  display: flex;
  background-color: white;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;  
`;

const StyledLay = styled.div`
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Styledh5 = styled.h5`
  font-weight: 400;
  font-size: 17px;
  font-family: "Roboto Condensed", sans-serif;
  color: #b4b4b4;
`;

const Styledh4 = styled.h4`
  font-family: "Raleway";
  font-weight: 800;
  font-size: 24px;
`;

const StyledButton = styled.button`
  background-color: transparent;
  color: #5d5d5d;
  font-weight: 600;
  font-size: 14px;
  font-family: Raleway;
  width: 250px;
  height: 45px;
  margin: 0 5px;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: rgba(111, 133, 147, 0.1);
    color: #673AB7 ;

  }
  &.active {
    background-color: rgba(111, 133, 147, 0.1);
      color: #673AB7 ;

  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const DashboardSm = () => {
    const [data, setData] = useState(yearlyData);
    const [activeButton, setActiveButton] = useState('yearly');

    const handleButtonClick = (dataType) => {
        setData(dataType);
        switch (dataType) {
            case weeklyData:
                setActiveButton('weekly');
                break;
            case monthlyData:
                setActiveButton('monthly');
                break;
            case yearlyData:
                setActiveButton('yearly');
                break;
            default:
                setActiveButton('yearly');
        }
    };

    return (
        <Containerdiv>
            <StyledDiv>
                <div className='flex'>
                    <StyledLay style={{ backgroundColor: " #255781" }}>
                        <PiStudent style={{ width: "30px", height: "30px", color: "white" }} />
                    </StyledLay>
                    <div className='mx-2'>
                        <Styledh5>Öğrenci Sayısı</Styledh5>
                        <Styledh4>953</Styledh4>
                    </div>
                </div>
                <div className='flex'>
                    <StyledLay style={{ backgroundColor: "#f36f25" }}>
                        <LiaChalkboardTeacherSolid style={{ width: "30px", height: "30px", color: "white" }} />
                    </StyledLay>
                    <div className='mx-2'>
                        <Styledh5>Öğretmen Sayısı</Styledh5>
                        <Styledh4>23</Styledh4>
                    </div>
                </div>
                <div className='flex'>
                    <StyledLay style={{ backgroundColor: "#1bc3c3" }}>
                        <RiSchoolLine style={{ width: "25px", height: "25px", color: "white" }} />
                    </StyledLay>
                    <div className='mx-2'>
                        <Styledh5>Sınıf Sayısı
                        </Styledh5>
                        <Styledh4>18</Styledh4>
                    </div>
                </div>
            </StyledDiv>

            <div className='flex w-full'>
                <div className='w-2/3 bg-white mt-8 p-7 mr-5 rounded-xl'  style={{boxShadow:" rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                    <Statistics />
                </div>
                <div className='w-1/3 '>
                    <div className='mt-8 p-7 pb-3  rounded-xl bg-[#ffffff] w-full relative overflow-hidden  cursor-pointer' style={{boxShadow:" rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                        <div className='rounded-full w-52 h-52 bg-[#1e88e538] absolute top-[-110px] right-[-20px]'></div>
                        <div className='rounded-full w-52 h-52 bg-[#1565c057] absolute top-[-90px] right-[-100px]'></div>
                        <div style={{ backgroundColor: "#145186", width: "50px", height: "50px", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "10px" }}>
                            <GiGraduateCap     style={{ width: "27px", height: "27px", color: "white", }} />
                        </div>
                        <Styledh4 style={{ color: "#294866", fontWeight: "700", fontSize: "25px", marginTop: "20px" }} >Öğrenci Ekle</Styledh4>
                        <div className='flex'>
                            <span style={{ color: "#596977", fontWeight: "400", fontSize: "15px",marginTop: "5px"  }}>*Toplu kayıt yapabilirsiniz. </span><IoTrendingUp style={{ width: "40px", height: "40px", color: "white", marginLeft: "8px" }} />
                        </div>
                    </div>

                    <div className=' mt-8 p-7 rounded-xl bg-[#ffffff]  w-full  relative   overflow-hidden cursor-pointer' style={{boxShadow:" rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                        <div className=' rounded-full w-52 h-52 bg-[#5e35b157] absolute top-[-110px] right-[-20px] '></div>
                        <div className=' rounded-full w-52 h-52 bg-[#4527a05c] absolute top-[-90px] right-[-100px] '></div>
                        <div style={{ backgroundColor: "#4527A0", width: "50px", height: "50px", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "10px" }}>
                            <MdNotificationsActive   style={{ width: "25px", height: "25px", color: "white", }} />
                        </div>
                        <Styledh4 style={{ color: "#113e66", fontWeight: "700", fontSize: "25px", marginTop: "20px" }} >Duyuru Gönder</Styledh4>
                        
                    </div>

                </div>
            </div>
            <Calendar />
        </Containerdiv>
    )
}

export default DashboardSm
