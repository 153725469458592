import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import bg from "../assets/images/bg.svg"
import Carousel from './Carousel';
import Card from './Card';
import cards from '../data/packets';
import click from "../assets/images/click.png"
import styled from 'styled-components';



const StyledTitle = styled.h6`
  font-weight: 800;
  font-size: 32px; 
  font-family: 'Onest';
  color: rgb(73, 85, 107);
 
`;
const StyledP = styled.p`
  font-weight: 400;
  font-size: 18px;
  font-family: 'Comfortaa';
  color: rgb(73, 85, 107);
  word-wrap: break-word;
`;
const ThirdSection = () => {
  const { ref: ref1, inView: inView1 } = useInView({
    triggerOnce: false,
    threshold: 0.5,
  });

  const { ref: ref2, inView: inView2 } = useInView({
    triggerOnce: false,
    threshold: 0.7,
  });

  const { ref: ref3, inView: inView3 } = useInView({
    triggerOnce: false,
    threshold: 0.9,
  });

  const cardVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
  };

  return (
    <div style={{
      paddingTop: "100px", zIndex: "-6", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", width: "100vw", height: "100vh", display: "flex", justifyContent: "center"
    }}>
      {/* <BsTriangleFill style={{ width: "500px", height: "500px", color: "#e2f9db", position: "absolute", top: "35%", right: "-10%", overflow: "hidden", zIndex: "-1", transform: "rotate(50deg)" }} />
      <GiRoundStar style={{ width: "400px", height: "400px", color: "#e7eff8", position: "absolute", top: "-20%", left: "40%", overflow: "hidden", zIndex: "-1000", transform: "rotate(30deg)" }} />
      <div style={{
        width: '450px',
        height: '450px',
        backgroundColor: '#f8e6f5',
        borderRadius: '50%',
        position: 'absolute',
        top: '90%',
        left: '10%',
        transform: 'translate(-50%, -50%)',
        zIndex: "-1"
      }}></div>
 */}
      <motion.div className="flex flex-wrap justify-around items-center py-20 px-20">
      <Carousel
          cards={cards}
          height="400px"
          width="40%"
          margin="0 auto"
          offset={200}
          showArrows={false}
        />
        <div className='w-1/2 px-10'>
          <div className='w-full block'>
            <img src={click} style={{ width: "60px", height: "60px", marginBottom: "20px" }} />
            <StyledTitle>Eğitim paketlerimizle öğrenmeyi güçlendirin </StyledTitle>
            <StyledP> <br /> Öğrencilerin gelişimini desteklemek için özenle hazırlanmış eğitim paketlerimizle öğrenme yolculuğunuzu güçlendirin. Her seviyeye uygun, eğlenceli ve etkili içeriklerle başarıya adım atın!
            </StyledP>
          </div>
        </div>

      </motion.div>
    </div>
  );
};

export default ThirdSection;
