import React, { useEffect, useState } from 'react';
import StaffDrawer from '../components/StaffDrawer.jsx';
import DashboardContent from '../components/DashboardSm.jsx';
import EditStudent from '../components/studentediting.jsx';
import ClassAdd from '../components/ClassAdd';
import ClassEdit from '../components/ClassEdit';
import styled from 'styled-components';
import { logout } from '../../../redux/userSlice.js';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Students from '../components/Students.jsx';
import AddTeacher from '../components/AddTeacher.jsx';
import DeleteTeacher from '../components/DeleteTeacher.jsx';
  
  const AppContainer = styled.div`
    display: flex;
    width: 100vw;
    height: 100vh;
    padding: 15px;
    overflow: hidden; 
   font-family: "Poppins", sans-serif;
  `;
  
  const ContentContainer = styled.div`
    transition: margin-left 0.3s;
    padding: 20px;
    width: 100%;
    height: 100vh; /* İçeriğin yüksekliği */
    overflow-y: auto; /* İçeriğin kaydırılmasını sağlar */
  `;
  
  const Dashboard = () => {
    const [selectedItem, setSelectedItem] = useState('anasayfa');
    const [drawerOpen, setDrawerOpen] = useState(true);
    const dispatch = useDispatch(); // Initialize dispatch
    const navigate = useNavigate(); // Initialize navigate
  
    const renderComponent = () => {
    switch (selectedItem) {
      case 'adminekle':
        // 'adminekle' için bir bileşen yoksa, null dönebilirsiniz
        return null;
      case 'ogrenciekle':
        return <Students />;
      case 'ogrenciduzenle':
        return <EditStudent />; // Öğrenci Düzenle bileşenini render edin
      case 'anasayfa':
        return <DashboardContent />;
        case 'ogretmenekle':
          return <AddTeacher/>;
      default:
        return <DashboardContent />;
      case 'sınıfekle':
        return <ClassAdd />; 
        case 'ogretmensil':
          return <DeleteTeacher/>;

      case 'sınıfduzenle':
        return <ClassEdit />; 
       }
      };
    const handleLogout = () => {
      localStorage.clear();
      dispatch(logout());
      navigate('/');
    };
  
  
    useEffect(() => {
      if (selectedItem === 'cikis') {
        handleLogout();
      }
    }, [selectedItem, handleLogout]); 
  
  
  
  
    return (
      <AppContainer>
        <StaffDrawer selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
        <ContentContainer drawerOpen={drawerOpen}>
          {renderComponent()}
        </ContentContainer>
      </AppContainer>
    );
  }
  
  export default Dashboard;
  