import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

export const QuestionContainer = styled.div`
  width: 80%;
  height: 80%;
`;

export const Question = styled.h6`
  font-family: Poppins;
  font-weight: 500;
  color: #ffffff;
  font-size: 19px;
  margin-bottom: 30px;
`;

export const Input = styled.input`
  padding: 10px;
  margin: 10px 0;
  border-radius: 10px;
  border: 2px solid #d2d2e9;
  width: 100%;
  font-size: 16px;
  font-family: "Varela";
`;

const BoslukDoldurmaTest = ({ currentQuestion, selectedAnswers, handleAnswerChange }) => {
  const [answer, setAnswer] = useState('');

  useEffect(() => {
    if (selectedAnswers[currentQuestion.id]) {
      setAnswer(selectedAnswers[currentQuestion.id].value || '');
    }
  }, [currentQuestion.id, selectedAnswers]);

  const handleInputChange = (event) => {
    setAnswer(event.target.value);
  };

  const handleSubmit = () => {
    if (currentQuestion) {
      handleAnswerChange(currentQuestion.id, { value: answer, index: 0 });
    }
  };

  if (!currentQuestion) {
    return <p>Soru veya boşluklar bulunamadı.</p>;
  }

  return (
    <QuestionContainer>
      <Question>{currentQuestion.question}</Question>
      <Input
        type="text"
        value={answer}
        onChange={handleInputChange}
        placeholder="Cevabınızı buraya yazın"
        onBlur={handleSubmit}
      />
    </QuestionContainer>
  );
};

export default BoslukDoldurmaTest;
