import React from 'react';
import styled from 'styled-components';

const QuestionContainer = styled.div`
  width: 80%;
  margin: 20px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const Question = styled.h3`
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color: #333;
  font-size: 18px;
  margin-bottom: 15px;
`;

const OptionButton = styled.div`
  width: 100%;
  padding: 15px;
  margin: 10px 0;
  border-radius: 10px;
  border: 2px solid ${props => 
    props.isUserAnswer 
      ? (props.isCorrect ? 'green' : 'red') 
      : (props.isCorrect ? 'green' : '#d2d2e9')
  };
  background-color: white;
  color: #4c4a70;
  font-weight: 500;
  text-align: left;
  font-family: "Varela", sans-serif;
  font-size: 16px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: #f9fafc;
  height: auto;
  justify-content: center;
  border-radius: 30px;
  padding: 15px;
`;

const ResultTrueFalse = ({ testResponse }) => {
  const { results } = testResponse;
  
  return (
    <Container>
      {results.map((result, index) => (
        <QuestionContainer key={result.question_id}>
          <Question>{`${index + 1}. ${result.question_text}`}</Question>
          <OptionButton
            isUserAnswer={result.user_answer === 1}
            isCorrect={result.correct_answer === 1}
          >
            Doğru
          </OptionButton>
          <OptionButton
            isUserAnswer={result.user_answer === 0}
            isCorrect={result.correct_answer === 0}
          >
            Yanlış
          </OptionButton>
        </QuestionContainer>
      ))}
    </Container>
  );
};

export default ResultTrueFalse;
