import bg from "../assets/images/packet.png";
import Card from "../components/Card";

const cards = [
  {
    id: 1,
    key: 1,
    content: (
      <Card 
        image={bg} 
        content="Öğrencilerin sınavda başarıya ulaşmalarını sağlamak için özel olarak tasarlanmıştır." 
        title="LGS Eğitim Paketi" 
        price={2500} 
        piece={1}
        id={1} 
        discount="15% İndirimli"
        key={1} 
      />
    )
  },
  {
    id: 2,
    key: 2,
    content: (
      <Card 
        image={bg} 
        content="Kapsamlı ders içerikleri ve sınav stratejileri ile donatılan bu paket, öğrencilerin hedeflerine emin adımlarla ilerlemelerine yardımcı olur." 
        price={1750} 
        title="8.Sınıf Eğitim Seti" 
        id={2} 
        piece={1}
        discount="15% İndirimli"
        key={2} 
      />
    )
  },
  {
    id: 3,
    key: 3,
    content: (
      <Card 
        image={bg} 
        content="Kapsamlı ders içerikleri ve sınav stratejileri ile donatılan bu paket, öğrencilerin hedeflerine emin adımlarla ilerlemelerine yardımcı olur." 
        price={3580} 
        piece={1}
        title="5.Sınıf Eğitim Seti" 
        discount="15% İndirimli"
        id={3} 
        key={3} 
      />
    )
  },
];

export default cards;
