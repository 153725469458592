import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import trLocale from '@fullcalendar/core/locales/tr';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Button, TextField, Typography, Box } from '@mui/material';

const CalendarWrapper = styled('div')`
  .calendar {
    * {
      font-family: 'Raleway', sans-serif;
    }

    .fc-toolbar-title {
      font-weight: 500;
      font-size: 1.2em;
      text-align: center;
    }

    .fc-button {
      width: 60px;
      background-color: #ffffff;
      color: #797979;
      border: 1px solid #cecece;
      font-size: large;
    }

    .fc-center {
      justify-content: center;
    }

    .fc-event-title {
      white-space: normal;
    }
  }
`;

const modalStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  p: 4,
};

const eventColor = 'rgb(243, 111, 37)';

const MyCalendar = () => {
  const [events, setEvents] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newEventTitle, setNewEventTitle] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedEventId, setSelectedEventId] = useState(null); // Etkinlik düzenlemesi için eklenen state

  useEffect(() => {
    setEvents([
      { id: '1', title: 'Etkinlik 1', date: '2023-07-01', backgroundColor: eventColor, borderColor: eventColor },
      { id: '2', title: 'Etkinlik 2', date: '2023-07-02', backgroundColor: eventColor, borderColor: eventColor },
    ]);
  }, []);

  const handleDateClick = (info) => {
    const clickedDate = info.dateStr;
    const clickedEvent = events.find(event => event.date === clickedDate);
    if (clickedEvent) {
      // Etkinlik varsa düzenlemek için modalı aç
      setSelectedEventId(clickedEvent.id);
      setNewEventTitle(clickedEvent.title);
    } else {
      // Etkinlik yoksa yeni etkinlik eklemek için modalı aç
      setSelectedEventId(null);
      setSelectedDate(clickedDate);
      setNewEventTitle('');
    }
    setModalIsOpen(true);
  };

  const handleEditEvent = (eventInfo) => {
    setSelectedEventId(eventInfo.event.id);
    setNewEventTitle(eventInfo.event.title);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedEventId(null); // Modal kapatıldığında etkinlik id'sini sıfırla
  };

  const handleSave = () => {
    if (newEventTitle && (selectedDate || selectedEventId !== null)) {
      if (selectedEventId) {
        // Etkinlik id'si varsa, mevcut etkinliği güncelle
        const updatedEvents = events.map(event => {
          if (event.id === selectedEventId) {
            return { ...event, title: newEventTitle };
          }
          return event;
        });
        setEvents(updatedEvents);
      } else {
        // Etkinlik id'si yoksa, yeni etkinlik ekle
        const newEvent = { id: String(events.length + 1), title: newEventTitle, date: selectedDate, backgroundColor: eventColor, borderColor: eventColor };
        setEvents([...events, newEvent]);
      }
      setNewEventTitle('');
      setSelectedDate(null);
      setModalIsOpen(false); // Modalı kapat
    }
  };

  return (
    <CalendarWrapper>
      <div className="calendar bg-white rounded-xl mt-8 p-7">
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          events={events}
          dateClick={handleDateClick}
          eventClick={handleEditEvent}
          selectable={true}
          locale={trLocale}
          headerToolbar={{
            left: 'prev,next',
            center: 'title',
            right: '',
          }}
          buttonText={{
            prev: '<',
            next: '>',
          }}
          titleFormat={{
            month: 'long',
            year: 'numeric',
          }}
          titleRangeSeparator={' - '}
          eventDisplay="block" // Etkinliklerin blok olarak görüntülenmesi (border yok)
          eventContent={(eventInfo) => (
            <div style={{ backgroundColor: eventInfo.event.backgroundColor, borderColor: eventInfo.event.borderColor, padding: '2px', borderRadius: '4px', border: `1px solid ${eventInfo.event.borderColor}` }}>
              {eventInfo.event.title}
            </div>
          )}
        />
      </div>

      <Modal
        open={modalIsOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyles}>
          <Typography variant="h5" component="h2" mb={2} sx={{ fontFamily: "'Poppins', sans-serif" }}>
            {selectedEventId ? 'Etkinliği Düzenle' : 'Etkinlik Ekle'}
          </Typography>
          <TextField
            sx={{ mt: 2 }}
            value={newEventTitle}
            onChange={(e) => setNewEventTitle(e.target.value)}
            label="Etkinlik başlığı"
            fullWidth
            variant="outlined"
          />
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button variant="outlined" onClick={closeModal} sx={{ color: '#194569', borderColor: '#194569' }}>
              İptal
            </Button>
            <Button variant="contained" onClick={handleSave} sx={{ ml: 2, bgcolor: '#194569', color: '#ffffff' }}>
              Kaydet
            </Button>
          </Box>
        </Box>
      </Modal>
    </CalendarWrapper>
  );
};

export default MyCalendar;
