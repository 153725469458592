const contentData = [{
  "unit": "Unit 1", // Buraya ekledik
  "sections": [
    {
      "section": "Vocabulary",
      "iframes": [
        {
          "title": "Words 1",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/b_bCuN/embed' width='1088' height='720' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        },
        {
          "title": "Words 2",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/_GCv8c/embed' width='1088' height='720' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        },
        {
          "title": "Words 3",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/z0vgGM/embed' width='1088' height='720' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        },
        {
          "title": "Words 4",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/4dLqP-/embed' width='1088' height='720' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        },
        {
          "title": "Words 5",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/JPD49-/embed' width='1088' height='720' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        },
        {
          "title": "Words 6",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/aLnU8p/embed' width='1088' height='720' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        }
      ]
    },
    {
      "section": "Grammer",
      "iframes": [
        {
          "title": "Grammer 1",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/tkWygi/embed' width='1088' height='720' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        },
        {
          "title": "Grammer 2",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/hc7ml4/embed' width='1088' height='720' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        }
      ]
    }
  ]
},
{
  "unit": "Unit 2",

  "sections": [
    {
      "section": "Vocabulary",
      "iframes": [
        {
          "title": "Words 1",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/KX7KPb/embed' width='1088' height='720' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        },
        {
          "title": "Words 2",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/O1xJzB/embed' width='1088' height='720' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        },
        {
          "title": "Words 3",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/MH2HQR/embed' width='1200' height='720' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>>"
        },

        {
          "title": "Words 4",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/ajHWRX/embed' width='1088' height='720' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        },

      ]
    },
    {
      "section": "Grammer",
      "iframes": [
        {
          "title": "Grammer 1",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/d6yZep/embed' width='1088' height='720' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        },
        {
          "title": "Grammer 2",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/58x_k7/embed' width='1088' height='720' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        },
      ]
    }
  ]

},
{
  "unit": "Unit 3",
  "sections": [
    {
      "section": "Vocabulary",
      "iframes": [
        {
          "title": "Words 1",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/_XUYy7/embed' width='1088' height='720' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        },
        {
          "title": "Words 2",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/w-xLhi/embed' width='1088' height='720' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        },
        {
          "title": "Words 3",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/AEXmH1/embed' width='1088' height='720' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        },
        {
          "title": "Words 4",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/h1rqCu/embed' width='1088' height=320' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        },
        {
          "title": "Words 5",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/G7C7ry/embed' width='1200' height='720' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        },
      ]
    },
    {
      "section": "Grammer",
      "iframes": [
        {
          "title": "Grammer 1",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/qzSptI/embed' width='850' height='850' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        },
        {
          "title": "Grammer 2",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/EXz0XB/embed' width='1088' height='720' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        },
        {
          "title": "Grammer 3",
          "content": "<iframe src='https://app.Lumi.education/api/v1/run/ezbsBV/embed' width='850' height='920' frameborder='0' allowfullscreen='allowfullscreen' allow='geolocation *; microphone *; camera *; midi *; encrypted-media *'></iframe><script src='https://app.Lumi.education/api/v1/h5p/core/js/h5p-resizer.js' charset='UTF-8'></script>"
        },
      ]
    }
  ]

},

{
  "unit": "Unit 4",
  "sections": [
    {
      "section": "Vocabulary",
      "iframes": [
        {
          "title": "Words 1",
          "content": "<iframe src='https://quizizz.com/embed/quiz/6721e2ceb9b930b8d39a2468'  width='1088' height='720' frameborder='0' allowfullscreen></iframe>"
        },
      ]
    },
    {
      "section": "Grammer",
      "iframes": [

      ]
    }
  ]

}


]

export default contentData;
