import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// Styled Components
const QuestionContainer = styled.div`
  width: 80%;
  height: 80%;
  padding: 20px;
`;

const Question = styled.h6`
  font-family: Poppins, sans-serif;
  font-weight: 500;
  color: rgb(76, 74, 112);
  font-size: 19px;
  margin-bottom: 30px;
`;

const OptionButton = styled.button`
  width: 100%;
  padding: 15px;
  margin: 10px 0;
  border-radius: 20px;
  border: 2px solid ${({ selected }) => (selected ? '#ea517c' : '#d2d2e9')};
  background-color:#ffffff;
  color: ${({ selected }) => (selected ? '#ea517c' : 'rgb(76, 74, 112)')};
  font-weight: bold;
  text-align: left;
  cursor: pointer;
  font-family: "Varela", sans-serif;
  font-size: 16px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;

  &:hover {
    color: #ea517c;
    border-color: #ea517c;
  }
`;

const DogruYanlisTest = ({ currentQuestion, selectedAnswers, handleAnswerChange }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (currentQuestion && selectedAnswers[currentQuestion.id] !== undefined) {
      setSelectedOption(selectedAnswers[currentQuestion.id]);
    } else {
      setSelectedOption(null); // Varsayılan olarak cevapsız
    }
  }, [currentQuestion, selectedAnswers]);

    const handleOptionClick = (option) => {
    setSelectedOption(option); // seçilen seçeneği state'e ekle
    handleAnswerChange(currentQuestion.id, option); // cevabı üst bileşene bildir
  };

  if (!currentQuestion) {
    return <p>Soru bulunamadı.</p>;
  }

  return (
    <QuestionContainer>
      <Question>{currentQuestion.question}</Question>
      <OptionButton
        selected={selectedOption === 1} // Doğru için 1
        onClick={() => handleOptionClick(1)} // Doğru için 1
      >
        Doğru
      </OptionButton>
      <OptionButton
        selected={selectedOption === 0} // Yanlış için 0
        onClick={() => handleOptionClick(0)} // Yanlış için 0
      >
        Yanlış
      </OptionButton>
    </QuestionContainer>
  );
};

export default DogruYanlisTest;
