import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const Title = styled.h4`
  text-align: center;
  margin-bottom: 20px;
  font-family: Raleway;
  font-weight: 600;
  font-size: 24px;
  color: #666666;
`;

const StyledForm = styled.form`
  width: 40%;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: rgba(113, 113, 113, 0.05) 0px 0px 0px 1px;
  padding-left: 60px;
  padding-right: 60px;
`;

const StyledLabel = styled.label`
  color: #555;
  font-family: "Raleway";
  font-weight: 500;
  margin-bottom: 5px;
  margin-left: 5px;
  display: block;
`;

const StyledInput = styled.input`
  width: 100%;
  margin-bottom: 10px;
  padding: 12px;
  border-radius: 15px;
  border: 1px solid #ccc;
  outline: none;

  &:hover {
    border-color: #eaeaea;
  }

  &:focus {
    border-color: #B39DDB;
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  margin-top: 20px;
  background-color: #2f2f30;
  color: #fff;
  border: none;
  padding: 12px;
  border-radius: 15px;
  cursor: pointer;

  &:hover {
    background-color: #161617;
  }
`;

const AdminEkle = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    name: '',
    surname: '',
    phone: '',
  });




  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://api.ogulcanarslan.com.tr/api/register', formData);
      console.log(response.data);
      // Başarılı bir şekilde eklendiğinde kullanıcıya bildirim gösterilebilir
    } catch (error) {
      console.error('Error adding admin:', error);
    }
  };

  return (
    <div>
      <div style={{ backgroundColor: "rgba(111, 133, 147, 0.1)", borderRadius: "20px", padding: "40px" }}>
        <Title>Yeni Admin</Title>
        <StyledForm onSubmit={handleSubmit}>
          <div className='flex gap-3 '>
          <div>
            <StyledLabel>Ad</StyledLabel>
            <StyledInput
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className=' '>
            <StyledLabel>Soyad</StyledLabel>
            <StyledInput
              type="text"
              name="surname"
              value={formData.surname}
              onChange={handleChange}
              required
            />
          </div>
          </div>

          <div>
            <StyledLabel>Telefon</StyledLabel>
            <StyledInput
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <StyledLabel>Email</StyledLabel>
            <StyledInput
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <StyledLabel>Şifre</StyledLabel>
            <StyledInput
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <SubmitButton type="submit">
              Ekle
            </SubmitButton>
          </div>
        </StyledForm>
      </div>
    </div>
  );
};

export default AdminEkle;
