import React, { useState, useRef } from 'react';
import styled from 'styled-components';

// Styled Components
const TestContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 40px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
`;

const SideContainer = styled.div`
  width: 45%;
  position: relative;
`;

const Item = styled.div`
  padding: 20px;
  margin: 15px 0;
  background-color: #ffffff;
  color: #333;
  font-weight: bold;
  border-radius: 12px;
  cursor: grab;
  user-select: none;
  transition: transform 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative; /* Add relative positioning */
  &:hover {
    transform: scale(1.05);
    background-color: #e2e8f0;
  }
`;

const DropZone = styled.div`
  padding: 20px;
  margin: 15px 0;
  border: 2px dashed #a0aec0;
  border-radius: 12px;
  background-color: ${({ isOver }) => (isOver ? '#bee3f8' : '#edf2f7')};
  color: #333;
  text-align: center;
  transition: all 0.3s ease;
  position: relative; /* Add relative positioning */
`;

const MatchedItem = styled.div`
  padding: 10px;
  margin: 15px 0;
  background-color: #f7fafc;
  color: #2d3748;
  font-weight: 500;
  border-radius: 12px;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
`;

// Helper function to get the position of the item and dropzone
const getArrowPosition = (itemElement, dropZoneElement) => {
  if (!itemElement || !dropZoneElement) return { top: 0, left: 0, length: 0, transform: 'none' };

  const itemRect = itemElement.getBoundingClientRect();
  const dropZoneRect = dropZoneElement.getBoundingClientRect();

  const x1 = itemRect.right;
  const y1 = itemRect.top + itemRect.height / 2;
  const x2 = dropZoneRect.left;
  const y2 = dropZoneRect.top + dropZoneRect.height / 2;

  const length = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
  const angle = Math.atan2(y2 - y1, x2 - x1) * (180 / Math.PI);

  return {
    top: y1 - itemRect.top,
    left: x1 - itemRect.left,
    length,
    transform: `rotate(${angle}deg)`,
  };
};

const SurukleBirakTest = ({ currentQuestion }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [itemDropZoneMap, setItemDropZoneMap] = useState({});
  const itemRefs = useRef({});
  const dropZoneRefs = useRef({});

  const handleDragStart = (itemId) => {
    setSelectedItem(itemId);
  };

  const handleDrop = (dropZoneId) => {
    if (selectedItem) {
      setItemDropZoneMap((prevMap) => ({
        ...prevMap,
        [selectedItem]: dropZoneId,
      }));
      setSelectedItem(null);
    }
  };

  if (!currentQuestion) {
    return <p>Bir hata oluştu. Soru verisi bulunamadı.</p>;
  }

  return (
    <TestContainer>
      <SideContainer>
        {currentQuestion.items.map((item) => (
          <Item
            key={item.id}
            id={`item-${item.id}`}
            ref={(el) => (itemRefs.current[item.id] = el)}
            draggable
            onDragStart={() => handleDragStart(item.id)}
          >
            {item.content}
          </Item>
        ))}
      </SideContainer>
      <SideContainer>
        {currentQuestion.dropzones.map((dropZone) => (
          <DropZone
            key={dropZone.id}
            id={`dropzone-${dropZone.id}`}
            ref={(el) => (dropZoneRefs.current[dropZone.id] = el)}
            onDragOver={(e) => e.preventDefault()}
            onDrop={() => handleDrop(dropZone.id)}
            isOver={Object.values(itemDropZoneMap).includes(dropZone.id)}
          >
            {dropZone.label}
          </DropZone>
        ))}
        <div>
          {Object.entries(itemDropZoneMap).map(([itemId, dropZoneId]) => (
            <MatchedItem key={`${itemId}-${dropZoneId}`}>
              <span>
                {currentQuestion.items.find((item) => item.id === itemId).content} ➔ 
                {currentQuestion.dropzones.find((dz) => dz.id === dropZoneId).label}
              </span>
            </MatchedItem>
          ))}
        </div>
      </SideContainer>
    </TestContainer>
  );
};

export default SurukleBirakTest;
