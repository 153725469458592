import React, { useState } from 'react';
import axios from 'axios';
import { Navigate } from "react-router-dom";
import "../../../index.css"
import { CiUser } from "react-icons/ci";
import { CiLock } from "react-icons/ci";
import bg from "../../../assets/images/bg1.jpg"
axios.defaults.withCredentials = true;

function LoginPage({ setAuthToken }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [redirect, setRedirect] = useState(false);

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            await axios.get('https://api.ogulcanarslan.com.tr/sanctum/csrf-cookie');

            const response = await axios.post('https://api.ogulcanarslan.com.tr/api/login', {
                email,
                password
            });

            const token = response.data.token;
            localStorage.setItem('auth_token', token);
            setAuthToken(token);
            setMessage(`Sitemize Hoş geldin, ${response.data.user.name}`);
            setRedirect(true);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setMessage('Kullanıcı adı veya şifre hatalı');
            } else {
                setMessage('Bir hata oluştu');
            }
        }
    };

    if (redirect) {
        return <Navigate to="/admin" replace={true} />;
    }




    return (
        <div style={{backgroundImage: `url(${bg})`,  backgroundRepeat: "no-repeat",  backgroundPosition: "bottom center",  backgroundAttachment: "fixed",backgroundSize: "cover"


        }}>
            <div class="wrap-login100 ">
                <form onSubmit={handleLogin} class="login100-form validate-form">
                    <span class="login100-form-title ">
                        Admin Giriş Paneli
                    </span>

                    <div class="wrap-input100 validate-input mb-23" data-validate="Username is reauired">
                        <span class="label-input100">Email</span>

                        <input class="input100" type="email" value={email}

                            onChange={(e) => setEmail(e.target.value)} name="username" placeholder="abc@gmail.com" />
                        <CiUser class="focus-input100" style={{ width: "20px", height: "20px", color: "#838383" }} />

                    </div>

                    <div class="wrap-input100 validate-input" data-validate="Password is required">
                        <span class="label-input100">Şifre</span>
                        <input value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            class="input100" type="password" name="pass" placeholder="1234567" />
                        <CiLock class="focus-input100" style={{ width: "20px", height: "20px", color: "#838383" }} />
                    </div>


                    <button type=' submit' style={{  color:"white",background: "linear-gradient(45deg, #2196f3, #7b1099)" }} className="w-full font-raleway rounded-lg mt-16	 " >
                        Giriş Yap
                    </button>




                </form>
            </div>


        </div>
    );
}

export default LoginPage;
