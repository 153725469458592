import React, { useState } from 'react';
import Lottie from 'react-lottie';
import animation from '../data/confused.json';
import styled from 'styled-components';

// Popover ve animasyon kapsayıcı stil ayarları
const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
  float: right;
  position: absolute;
  top: -60px;
  right: -60px;
`;

const Popover = styled.div`
  visibility: ${props => props.visible ? 'visible' : 'hidden'};
  position: absolute;
  top: 55%;
  left: 110%; /* Popover'ı okla hizalamak için left değerini ayarlıyoruz */
  transform: translateY(-50%);
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  font-family: Poppins;
  font-size: 14px;
  box-shadow: 0 4px 8px rgba(224, 224, 224, 0.2);
  z-index: 10;
  white-space: nowrap; /* İçeriğin taşmasını önler */
  /* Ok için stil ayarları */
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: -10px; /* Okun konumunu ayarlamak için left değeri */
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #e9e9e9; /* Okun rengini beyaz yapıyoruz */
    transform: translateY(-50%);
  }
`;

const LottieStyled = styled(Lottie)`
  width: 150px;
  height: 150px;
`;

const LottieLessons = ({ lesson }) => {
  const [isPaused, setIsPaused] = useState(true);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: false, // Autoplay devre dışı bırakıldı
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid meet'
    }
  };

  return (
    <Wrapper
      onMouseEnter={() => {
        setIsPaused(false);
        setIsPopoverVisible(true);
      }}
      onMouseLeave={() => {
        setIsPaused(true);
        setIsPopoverVisible(false);
      }}
    >
      <LottieStyled
        options={defaultOptions}
        isPaused={isPaused} // isPaused state'ine göre animasyon durdurulacak veya oynatılacak
        height={150}
        width={150}
      />
      <Popover visible={isPopoverVisible}>
        Cevabından emin değilsen buraya tıkla! <br/> Soruya geri dönmen daha kolay olucak.
      </Popover>
    </Wrapper>
  );
};

export default LottieLessons;
