import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { HiOutlineChevronDown } from 'react-icons/hi';
import { IoMdLogIn } from "react-icons/io";
import student from "../assets/images/studentt.png";
import manager from "../assets/images/manager.png";
import teacher from "../assets/images/teacherr.png";
import { AiOutlineLogin } from "react-icons/ai";
import { RiRobot3Fill } from "react-icons/ri";
import { MdOutlineLaptopMac } from "react-icons/md";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { CgClose } from "react-icons/cg";
import { useDispatch, useSelector } from 'react-redux';
import { PiStudent } from "react-icons/pi";
import { HiChevronDown } from "react-icons/hi2";
import { Menu, MenuItem, IconButton } from '@mui/material';
import { logout } from '../redux/userSlice';
import { IoMdArrowDropdown } from "react-icons/io";

//koyu mavi #384152
//mavi :#d6e2f2
//pembe:#e485b3
//mavi-mor:#b2b1ee
// turuncu : #f36f25
//pembe: #fbebd2
//canlı pembe #ffc3be

const Containerdiv = styled.div`
  box-shadow: none;
  padding-top: 20px;
  display: flex;
  z-index: 1;
  justify-content: space-between;
  align-items: center;

`;

const StyledDiv = styled.div`
  height: 80px;
  border-radius: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
`;

const Styledh6 = styled.h6`
  font-weight: 500;
  font-size: 17px;
  font-family: 'Raleway', sans-serif;
  color: rgb(73, 85, 107);
  display: inline;
  background-color: transparent;
  cursor: pointer;
  position: relative;
  transition: color 0.3s ease; /* Renk geçişi için transition ekledik */
  text-decoration: none;

 
`;


const DropdownContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  display: ${props => (props.isOpen ? 'block' : 'none')};
`;

const DropdownItem = styled.div`
  position: relative;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e6edf7;
  }

  &:hover > div {
    display: block;
  }
`;

const SubDropdownContainer = styled.div`
  position: absolute;
  top: 0;
  left: 100%;
  margin-left: 15px;
  z-index: 999;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  display: none;
`;

const Buttondiv = styled.div`
  position: relative;
  cursor: pointer;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  gap: 7px;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 5px;
  &:first-child {
    border-left: none;
  }
`;

const Buttons = styled.div`
  cursor: pointer;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 15px 20px;
  display: flex;
  box-shadow: rgba(191, 191, 191, 0.16) 0px 1px 4px;
  align-items: center;
  gap: 15px;
`;

const Buttonlogin = styled.div`
  cursor: pointer;
  padding: 20px;
  padding-left: 25px;
  z-index: 99;
  padding-right: 25px;
  display: flex;
  align-items: center;
`;
const DialogContentCentered = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh; /* Modalin yüksekliğinin %80'i kadar boşluk bırakır */
`;

const Acc = styled.div`
  border-radius: 13px;
  height: 60px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  padding: 10px 20px;
  box-shadow: rgba(191, 191, 191, 0.16) 0px 1px 4px;
   border: 2px solid rgb(73, 85, 107);
  transition: background-color 0.5s ease;
  &:hover {
    background-color: #1a192297;
  }
`;
const StyledTitle = styled.h6`
  font-weight: 500;
  font-size: 17px;
  font-family: 'Raleway', sans-serif;
  color: rgb(73, 85, 107);
  display: inline;
  background-color: transparent;
  cursor: pointer;
  position: relative;
  transition: color 0.3s ease; 
  text-decoration: none;

  ${Acc}:hover & {
    color: #ffffff;
  }
 
`;
const StyledIcon = styled(IoMdArrowDropdown)`
  color: rgb(73, 85, 107); /* Varsayılan renk */
  transition: color 0.3s ease; /* Renk geçişi için transition ekledik */

  ${Acc}:hover & {
    color: #f0eff5; /* Hover durumunda değişecek renk */
  }
`;
const Down = styled(HiChevronDown)`
  color: #68659e; /* Varsayılan renk */
  transition: color 0.3s ease; /* Renk geçişi için transition ekledik */

  ${Acc}:hover & {
    color: #f0eff5; /* Hover durumunda değişecek renk */
  }
`;
const StyledMenu = styled(Menu)`
  & .MuiPaper-root {
    width: 200px;
    border-radius: 10px;
    padding: 2px;
    font-family: "Poppins";
    box-shadow:none;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  padding: 10px 15px;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 5px;

  transition: background-color 0.2s ease;

  &:hover {
    background-color: #f0f0f0;
  }

  &:last-child {
    border-bottom: none; // Son menü öğesi için alt çizgi kaldırma
  }
`;
const Navbar = () => {

  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleLoginClick = () => {
    navigate('/user-login');
  };

  const handleModalOpen = () => {
    setIsOpen(true);
  };

  const handleModalClose = () => {
    setIsOpen(false);
  };
  const userInfo = useSelector((state) => state.user.userInfo);
  console.log(userInfo)
  const handleDropdownOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };
  const dispatch = useDispatch(); // Redux dispatch fonksiyonunu al

  const handleLogout = () => {
    // localStorage'ı temizle
    localStorage.clear();

    // Redux state'inden kullanıcı bilgilerini sil
    dispatch(logout());

    // Kullanıcıyı giriş sayfasına yönlendir
    navigate('/');
  };

  return (
    <Containerdiv >
      <div className='flex pl-20 z-10'>
        <Buttons>
          <Styledh6>Paketler</Styledh6>
          <MdOutlineLaptopMac style={{ width: '20px', height: '20px', marginLeft: '5px', color: "rgb(73, 85, 107)", backgroundColor: 'transparent', display: 'inline', }} />
        </Buttons>
      </div>
      <StyledDiv>
        <RiRobot3Fill style={{ width: '40px', height: '40px', marginLeft: '5px', color: "#699595", backgroundColor: 'transparent', display: 'inline', }} />
        <div className="mx-3" style={{ zIndex: '999', fontFamily: "'Poppins', sans-serif", fontWeight: 'bold', fontSize: '22px', position: 'relative', lineHeight: '90%' }}>
          <span style={{ color: '#699595', position: 'absolute', fontSize: "25px", top: '0', left: '0' }}>
            rob
          </span>  <span style={{ color: '#7da4a4', position: 'absolute', fontSize: "25px", top: '0', left: '45px' }}>
            ot
          </span>
          <br />
          <span style={{ color: '#699595', fontSize: "25px" }}>ark</span>
          <span style={{ color: '#7da4a4', fontSize: "25px" }}>adaşım</span>
        </div>
      </StyledDiv>

      {userInfo ? (<div className="flex pr-20 z-10"     onMouseEnter={handleDropdownOpen}
    onMouseLeave={handleDropdownClose}
> <Acc >       <StyledTitle>Hesabım</StyledTitle>   <StyledIcon style={{ width: '15px', height: '15px', marginLeft: '5px', display: 'inline', }} />

      </Acc>                     <StyledMenu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleDropdownClose}
            PaperProps={{
              style: {
                marginLeft: '-20px',
                position: 'absolute',
                top: '100%',
                left: '0',
              },
            }}
          >
            <StyledMenuItem onClick={ ()=> navigate('/student')}>Anasayfam</StyledMenuItem>
            <StyledMenuItem onClick={handleDropdownClose}>Ayarlarım</StyledMenuItem>
            <StyledMenuItem onClick={handleLogout}>Çıkış yap</StyledMenuItem>
          </StyledMenu>
      </div>
      ) : (<div className="flex pr-20 z-10">
        <Buttons className='' onClick={handleModalOpen}>
          <Styledh6>Giriş Yap</Styledh6>
          <AiOutlineLogin style={{ width: '20px', height: '20px', marginLeft: '5px', color: "#68659e", backgroundColor: 'transparent', display: 'inline', }} />
        </Buttons>
      </div>)}

      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleModalClose}
      >
        <DialogContent>
          <DialogActions>
            <button onClick={handleModalClose} color="primary">
              <CgClose style={{ width: '30px', height: '30px', color: "#8b89a6", }} />

            </button>
          </DialogActions>
          <DialogContentCentered>
            <div className='flex justify-center gap-28 '>
              <div> <div onClick={() => navigate("/teacher/login")} style={{ width: "150px", height: "150px", borderRadius: "50%", backgroundColor: "#ebebf4", display: "flex", justifyContent: "center", alignItems: "center", border: "1px solid #747474", cursor: "pointer" }}> <img src={teacher} style={{ width: "100px", height: "100px", cursor: "pointer", }} /></div><p style={{ fontFamily: "Raleway", fontSize: "20px", color: "#454369", textAlign: "center", fontWeight: "600", marginTop: "10px" }}>Öğretmenim</p>   </div>
              <div> <div onClick={() => navigate("/student/login")} style={{ width: "150px", height: "150px", borderRadius: "50%", backgroundColor: "#ebebf4", display: "flex", justifyContent: "center", alignItems: "center", border: "1px solid #747474", cursor: "pointer" }}> <img src={student} style={{ width: "100px", height: "100px", cursor: "pointer", }} /></div><p style={{ fontFamily: "Raleway", fontSize: "20px", color: "#454369", textAlign: "center", fontWeight: "600", marginTop: "10px" }}>Öğrenciyim</p>   </div>
              <div> <div onClick={() => navigate("/staff/login")}  style={{ width: "150px", height: "150px", borderRadius: "50%", backgroundColor: "#ebebf4", display: "flex", justifyContent: "center", alignItems: "center", border: "1px solid #747474", cursor: "pointer" }}> <img src={manager} style={{ width: "100px", height: "100px", cursor: "pointer", }} /></div><p style={{ fontFamily: "Raleway", fontSize: "20px", color: "#454369", textAlign: "center", fontWeight: "600", marginTop: "10px" }}>Yetkiliyim</p>   </div>
            </div>
          </DialogContentCentered>
        </DialogContent>

      </Dialog>

    </Containerdiv>
  );
};




export default Navbar;
