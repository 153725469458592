import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../../redux/userSlice.js';
import { FaLinesLeaning } from "react-icons/fa6";
import { IoIosNotificationsOutline } from "react-icons/io";
import { IoIosSearch } from "react-icons/io";
import { IoIosMenu } from "react-icons/io";


import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import { RiDashboardFill } from "react-icons/ri";
import { PiVideoFill } from "react-icons/pi";
import { HiDocumentChartBar } from "react-icons/hi2";
import { FaFolderOpen } from "react-icons/fa6";
import { BsChatRightQuoteFill } from "react-icons/bs";

const theme = {
  spacing: (factor) => `${factor * 8}px`, // spacing fonksiyonunu manuel ekledik
};

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(() => ({
  '& .MuiPaper-root': {
    borderRadius: 15,
    marginTop: theme.spacing(1), // Manuel olarak eklenen spacing fonksiyonu burada kullanılıyor
    minWidth: 200,
    color: 'rgb(55, 65, 81)',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '20px 0',
      
    },
    '& .MuiMenuMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: '#6b7280', // theme.palette.text.secondary yerine sabit bir renk kullanıldı
        marginRight: theme.spacing(1.5),
        padding:'20px',
      },
      '&:active': {
        backgroundColor: 'rgba(184, 194, 204, 0.12)', // alpha fonksiyonunun çıktısı sabit ayarlandı
      },
    },
  },
}));

const Search = styled.input`
    width: 300px;
    height:50px;
    outline: none;
    background-color: #fafafa;
    padding-left: 30px;
    border-radius: 50px;
    font-family: 'Poppins', sans-serif;
    color: #384152;
    border: 2px solid #4d576a;
    &::placeholder {
      color: #384152;
      font-family: 'Poppins', sans-serif;
      font-size: 15px;
    }
`;
const Styledh6 = styled.h6`
  font-weight: 600;
  font-size: 23px;
  font-family: 'Rubik', sans-serif;
  color: #4c5565;
  display: inline;
  text-decoration: none;
`;

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  font-family: "Poppins", sans-serif;

`;

const ContentContainer = styled.div`
  width: 100%;
`;
const NavigationBar = ({item}) => {

    const dispatch = useDispatch(); // Initialize dispatch
  const navigate = useNavigate(); // Initialize navigate


  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };



  const handleLogout = () => {
    localStorage.clear();
    dispatch(logout());
    navigate('/');
  };



  return (
    <div className='flex justify-between items-center px-10 py-4'>

    <Styledh6><FaLinesLeaning style={{ width: "25px", height: "25px", color: "#384152", display: "inline", marginRight: "10px" }} />{item}</Styledh6>
    <div className='flex items-center'>
      <form action="#" method="post" style={{ display: "inline", position: "relative", marginRight: "30px" }}>
        <Search type="text" name="search" placeholder="Buradan ara" />
        <IoIosSearch style={{ width: "25px", height: "25px", color: "#384152", position: "absolute", top: "25%", right: "20px" }} />
      </form>
      <IoIosNotificationsOutline style={{ width: "35px", height: "35px", color: "#4a5467", display: "inline", marginRight: "25px", cursor: "pointer" }} />

      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        onClick={handleClick} >
        <IoIosMenu style={{ width: "30px", height: "30px", color: "#4a5467", cursor: "pointer" }} />

      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => navigate('/student')} disableRipple>
          <RiDashboardFill style={{ width: "17px", height: "17px" ,marginRight:"10px"}} />
          Anasayfam
        </MenuItem>
        <MenuItem onClick={() => navigate('/student/lessons')} disableRipple>
          <PiVideoFill style={{ width: "17px", height: "17px", marginRight:"10px"}} />
          Dersler
        </MenuItem>
        <MenuItem onClick={() => navigate('/student/tests')} disableRipple>
          <HiDocumentChartBar style={{ width: "17px", height: "17px", marginRight:"10px"}} />
          Testler
        </MenuItem>
        <MenuItem onClick={() => navigate('/student/chatbot')} disableRipple>
          <BsChatRightQuoteFill style={{ width: "17px", height: "17px" ,marginRight:"10px"}} />
          Chatbot
        </MenuItem>
        <MenuItem onClick={() => navigate('/student/homeworks')} disableRipple>
          <FaFolderOpen style={{ width: "17px", height: "17px" ,marginRight:"10px"}} />
          Ödevler
        </MenuItem>
      </StyledMenu>

    </div>
  </div>  )
}

export default NavigationBar