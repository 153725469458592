import React from 'react';
import styled from 'styled-components';
import ResultMultiple from '../manager/ResultMultiple.jsx';
import BoslukDoldurmaTest from '../manager/FillInTheBlankQuestion.jsx';
import ResultTrueFalse from "./ResultTrueFalse.jsx";
import SurukleBirakTest from './DragAndDropQuestion.jsx';
import star from "../../../assets/images/star.png";
import { CiCircleQuestion } from "react-icons/ci";
import { CiCircleCheck } from "react-icons/ci";
import { CiCircleRemove } from "react-icons/ci";



const ResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: auto;
  padding: 20px;
  gap: 30px;
  background-color: #f9fafc;
  overflow-y: auto;
  border-radius: 10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  justify-content: center;
  width: 50%;
  border-radius: 30px;
  padding: 15px;
  margin-right: 300px;
`;
const Sidebar = styled.div`
  width: 300px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  overflow-y: auto;
  position: absolute;
  right: 0;
  justify-content: center;
`;
const QuestionInfo = styled.div`
  font-family: "Jost";
  color: #b4b8c5;
  margin-bottom: 5px;
  font-size: 25px;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  width: 100%;
  height: 60px;
  background-color: white;
  float: left;
  display: flex;
  padding: 5px 25px;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;

`;
const Title = styled.p`
  font-family: "Poppins";
  font-weight: 600;
  color: #5a597f;
  text-align: center;
  font-size: 18px;
`;

const SubTitle = styled.p`
  font-family: "Poppins";
  font-weight: 500;
  color: #5a597f;
  text-align: center;
  font-size: 18px;
`;
const ResultSummary = styled.div`
  text-align: center;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  color: #333;
`;
const TestResults = ({ testResponse, selectedTestType }) => {
  console.log("Gelen parametreler:", testResponse);



  const renderResultComponent = (testResponse) => {
 

    switch (selectedTestType) {
      case '2':
        return (
          <ResultMultiple
         testResponse={testResponse}
          />
        );
      case '3':
        return (
          <BoslukDoldurmaTest
    
          />
        );
      case '1':
        return (
          <ResultTrueFalse
          testResponse={testResponse}

          />
        );
      case '4':
        return (
          <SurukleBirakTest

          />
        );
      default:
        return <p>Test türü tanımlı değil.</p>;
    }
  };
  const { correct_count, incorrect_count, score, results,unanswered_count} = testResponse;

  return (
    <ResultsContainer>
      <Title >Test Sonucu</Title>
      <ResultSummary>
        <div style={{backgroundImage:"linear-gradient(120deg, #ff867b 0%, #e182f4e1 100%)",position:"relative",height:"200px",width:"200px",borderRadius:"50%",textAlign:"center",display:"flex",alignItems:"center",justifyContent:"center"}}> 
          <div style={{backgroundColor:"white",height:"180px",width:"180px",borderRadius:"50%",textAlign:"center",display:"flex",alignItems:"center",justifyContent:"center"}}>
             <span style={{fontSize:"70px",color:"#4c4a70",fontFamily:"Poppins",fontWeight:"600"}}>{score}</span></div> 
             <img src={star} style={{width:"70px",height:"70px",position:"absolute",bottom:"-10px",left:"-5px"}} />  </div>
             <div className='w-full mt-10'>
             <QuestionInfo><Title>Doğru Sayısı :  {correct_count}</Title>  <CiCircleCheck style={{width:"35px",height:"35px",color:"green",marginRight:"2px"}} /> </QuestionInfo>
             <QuestionInfo><Title>Yanlış Sayısı :  {incorrect_count}</Title>  <CiCircleRemove  style={{width:"35px",height:"35px",color:"red",marginRight:"2px"}} /> </QuestionInfo>
             <QuestionInfo><Title>Boş Sayısı :  {unanswered_count}</Title>  <CiCircleQuestion   style={{width:"33px",height:"33px",color:"#6e6e6e ",marginRight:"2px"}} /> </QuestionInfo>
             </div>
      </ResultSummary>
          {renderResultComponent(testResponse)}
    </ResultsContainer>
  );
};

export default TestResults;
