import React, { useState } from 'react';
import styled from 'styled-components';
import { PiStudent } from "react-icons/pi";
import { RiSchoolLine } from "react-icons/ri";
import { weeklyData, monthlyData, yearlyData } from "../data/data.js"
import { Line } from 'react-chartjs-2';
import { CategoryScale, Chart } from "chart.js";
import { registerables } from 'chart.js';
import { LiaChalkboardTeacherSolid } from "react-icons/lia";
import { MdOutlinePlayLesson } from "react-icons/md";
import { IoTrendingUp } from "react-icons/io5";
import { GiReceiveMoney } from "react-icons/gi";

Chart.register(...registerables);
Chart.register(CategoryScale);

const Containerdiv = styled.div`
  box-shadow: none;
  background-color: rgba(111, 133, 147, 0.1);
  border-radius: 10px;
  padding: 40px;
  margin-top: 20px;
`;

const StyledDiv = styled.div`
  height: 100px;
  border-radius: 10px;
  display: flex;
  background-color: white;
  justify-content: space-evenly;
  align-items: center;
`;

const StyledLay = styled.div`
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Styledh5 = styled.h5`
  font-weight: 400;
  font-size: 17px;
  font-family: "Roboto Condensed", sans-serif;
  color: #b4b4b4;
`;

const Styledh4 = styled.h4`
  font-family: "Raleway";
  font-weight: 800;
  font-size: 24px;
  margin-left: 5px;
`;

const StyledButton = styled.button`
  background-color: transparent;
  color: #5d5d5d;
  font-weight: 600;
  font-size: 14px;
  font-family: Raleway;
  width: 250px;
  height: 45px;
  margin: 0 5px;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: rgba(111, 133, 147, 0.1);
    color: #673AB7 ;

  }
  &.active {
    background-color: rgba(111, 133, 147, 0.1);
      color: #673AB7 ;

  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Dashboard = () => {
  const [data, setData] = useState(yearlyData);
  const [activeButton, setActiveButton] = useState('yearly');

  const handleButtonClick = (dataType) => {
    setData(dataType);
    switch (dataType) {
      case weeklyData:
        setActiveButton('weekly');
        break;
      case monthlyData:
        setActiveButton('monthly');
        break;
      case yearlyData:
        setActiveButton('yearly');
        break;
      default:
        setActiveButton('yearly');
    }
  };

  return (
    <Containerdiv>
      <StyledDiv>
        <div className='flex'>
          <StyledLay style={{ backgroundColor: "rgba(75,192,192,1)" }}>
            <PiStudent style={{ width: "30px", height: "30px", color: "white" }} />
          </StyledLay>
          <div className='mx-2'>
            <Styledh5>Öğrenciler</Styledh5>
            <Styledh4>2742</Styledh4>
          </div>
        </div>
        <div className='flex'>
          <StyledLay style={{ backgroundColor: "rgba(153,102,255,1)" }}>
            <LiaChalkboardTeacherSolid style={{ width: "30px", height: "30px", color: "white" }} />
          </StyledLay>
          <div className='mx-2'>
            <Styledh5>Öğretmenler</Styledh5>
            <Styledh4>453</Styledh4>
          </div>
        </div>
        <div className='flex'>
          <StyledLay style={{ backgroundColor: "rgba(255,159,64,1)" }}>
            <RiSchoolLine style={{ width: "25px", height: "25px", color: "white" }} />
          </StyledLay>
          <div className='mx-2'>
            <Styledh5>Okullar</Styledh5>
            <Styledh4>24</Styledh4>
          </div>
        </div>
      </StyledDiv>

      <div className='flex'>
        <div className='w-2/3 bg-white mt-8 p-7 mr-5 rounded-xl'>
          <div className='flex justify-between align-middle mx-3'>
            <Styledh4 style={{ color: "#34405e" }}>Kayıt özeti</Styledh4>
            <ButtonContainer className='w-1/2 float-end'>
              <StyledButton
                onClick={() => handleButtonClick(weeklyData)}
                className={activeButton === 'weekly' ? 'active' : ''}
              >
                Haftalık
              </StyledButton>
              <StyledButton
                onClick={() => handleButtonClick(monthlyData)}
                className={activeButton === 'monthly' ? 'active' : ''}
              >
                Aylık
              </StyledButton>
              <StyledButton
                onClick={() => handleButtonClick(yearlyData)}
                className={activeButton === 'yearly' ? 'active' : ''}
              >
                Yıllık
              </StyledButton>
            </ButtonContainer>
          </div>
          <Line
            data={data}
            options={{
              scales: {
                x: {
                  type: 'category'
                }
              }
            }}
          />
        </div>

        <div className='w-full'>
          <div className=' mt-8 p-7 rounded-xl bg-[#2196F3] w-full   relative   overflow-hidden'>
            <div className=' rounded-full w-52 h-52 bg-[#1E88E5] absolute top-[-110px] right-[-20px] '></div>
            <div className=' rounded-full w-52 h-52 bg-[#1565C0] absolute top-[-90px] right-[-100px] '></div>
            <div style={{ backgroundColor: "#1565C0", width: "50px", height: "50px", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "10px" }}>
              <MdOutlinePlayLesson style={{ width: "30px", height: "30px", color: "white", }} />
            </div>
            <Styledh4 style={{ color: "#ffffff", fontWeight: "700", fontSize: "29px", marginTop: "20px" }} >1460</Styledh4>
            <div className='flex'>
              <span style={{ color: "#ffffff", fontWeight: "400", fontSize: "22px", }}>Toplam kurs sayısı </span><IoTrendingUp style={{ width: "40px", height: "40px", color: "white", marginLeft: "8px" }} />
            </div>
          </div>
          <div className=' mt-8 p-7 rounded-xl bg-[#673AB7]  w-full  relative   overflow-hidden'>
            <div className=' rounded-full w-52 h-52 bg-[#5E35B1] absolute top-[-110px] right-[-20px] '></div>
            <div className=' rounded-full w-52 h-52 bg-[#4527A0] absolute top-[-90px] right-[-100px] '></div>
            <div style={{ backgroundColor: "#4527A0", width: "50px", height: "50px", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "10px" }}>
              <GiReceiveMoney style={{ width: "30px", height: "30px", color: "white", }} />
            </div>
            <Styledh4 style={{ color: "#ffffff", fontWeight: "700", fontSize: "29px", marginTop: "20px" }} >15.650 ₺</Styledh4>
            <div className='flex'>
              <span style={{ color: "#ffffff", fontWeight: "400", fontSize: "22px", }}>Toplam kazanç </span><IoTrendingUp style={{ width: "40px", height: "40px", color: "white", marginLeft: "8px" }} />
            </div>
          </div>

        </div>
      </div>
    </Containerdiv>
  )
}

export default Dashboard
