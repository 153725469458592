import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import s1 from "../assets/images/girl1.png";
import s2 from "../assets/images/girl2.png";
import s3 from "../assets/images/girl3.png";
import styled from 'styled-components';
import bg from "../assets/images/secondsection.png"
import flag from "../assets/images/goal.png"
import highlight from "../assets/images/highlight.png"
import { FaStar } from "react-icons/fa6";

const StyledDiv = styled.div`
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  width: 25%;
  padding: 50px;
  height: 50vh;
`;


const Wrapper = styled.div`
  border-radius: 10px;
  display: flex;
  justify-content: center;
  height: 80px;
  position: relative;
  width: 80px;
  align-items: center;
  margin-bottom: 20px;
`;

const StyledTitle2 = styled.h6`
  font-weight: 800;
  font-size: 34px; 
  margin-left: 6px;
  display: inline;
  font-family: 'Onest';
  color: rgb(73, 85, 107);
 
`;
const StyledTitle = styled.h6`
  font-weight: 800;
  font-size: 32px; 
  display: inline;
  font-family: 'Onest';
  color: rgb(255, 255, 255);
 
`;
const StyledArticle = styled.div`
  font-weight: 600;
  font-size: 19px;
  font-family: 'Varela';
  color: rgb(73, 85, 107);
  word-wrap: break-word;
  display: inline;

`;

const Title = styled.div`
  font-weight: 500;
  font-size: 18px; 
  text-align: center;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 102%;
  margin-bottom: -1px;

  font-family: 'Rubik';
  background-color:rgb(73, 85, 107) ;
  color: rgb(255, 255, 255);
`;


const StyledP = styled.p`
  font-weight: 600;
  font-size: 17px;
  font-family: 'Comfortaa';
  color: rgb(73, 85, 107);
  word-wrap: break-word;
`;

const Card = styled.div`
  height: 250px;
  width: 210px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  background-color: rgb(255, 255, 255);
  border: 5px solid rgb(73, 85, 107);
  border-radius: 10px;
  box-shadow: 10px 10px 0 rgb(54, 62, 79);
  justify-content: space-between; /* Elemanlar arası boşluk sağlar */
`;


const SecondSection = () => {

  return (
    <div style={{
      paddingLeft: "250px", paddingTop: "100px", zIndex: "-6", backgroundImage: `url(${bg})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", width: "100vw", height: "110vh", display: "flex"
    }} >
      <div className='w-1/3 block '>
        <img src={flag} style={{ width: "70px", height: "70px", marginBottom: "30px" }} />
        <div className='relative'>
          <img src={highlight} style={{ position: "absolute", top: "-10px", left: "-25px", width: "225px", zIndex: "0" }} />
          <div style={{ position: "relative", zIndex: "1" }}>
            <StyledTitle>Sıkılmadan </StyledTitle><StyledTitle2>öğren</StyledTitle2>
          </div>
        </div>
        <StyledP> <br />
          İnteraktif videolar ve eğlenceli içeriklerle dersleri keşfederken zamanın nasıl geçtiğini anlamayacaksın. Her konu, seni sıkmadan, kolayca öğrenebileceğin şekilde hazırlandı. Hem eğlen, hem de öğren!</StyledP> <br />
          <div style={{display:"flex",justifyContent:"center",flexDirection:"column"}}>
        <div><FaStar style={{ width: "25px", height: "25px", display: "inline", color: "#c094d3",marginRight:"5px" }} /><StyledArticle>100% MEB içeriği ile uyumlu</StyledArticle></div>  <br />
        <div>  <FaStar style={{ width: "25px", height: "25px", display: "inline", color: "#c094d3",marginRight:"5px" }} /><StyledArticle>İnteraktif ve eğlenceli öğrenme</StyledArticle></div>  <br />
        <div ><FaStar style={{ width: "25px", height: "25px", display: "inline", color: "#c094d3",marginRight:"5px" }} /><StyledArticle>Her zaman, her yerden erişim</StyledArticle></div>
        </div>
      </div>

    </div>
  );
};

export default SecondSection;
