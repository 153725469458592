import React from 'react';

const PaymentIframeComponent = ({ iframeSrc }) => {
  return (
    <div>
      <h1>Ödeme Sayfası</h1>
      <iframe
        src={iframeSrc}
        title="Payment"
        width="100%"
        height="600px"
        style={{ border: 'none' }}
      ></iframe>
    </div>
  );
};

export default PaymentIframeComponent;
