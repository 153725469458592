export const weeklyData = {
    labels: ['Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt', 'Paz'],
    datasets: [
        {
            label: 'z',
            data: [12, 19, 3, 5, 2, 3, 7],
            borderColor: 'rgba(75,192,192,1)',
            backgroundColor: 'rgba(75,192,192,0.2)',
        },
        {
            label: 'Öğretmen Sayısı',
            data: [2, 3, 20, 5, 1, 4, 8],
            borderColor: 'rgba(153,102,255,1)',
            backgroundColor: 'rgba(153,102,255,0.2)',
        },
        {
            label: 'Okul Sayısı',
            data: [3, 10, 13, 15, 22, 30, 45],
            borderColor: 'rgba(255,159,64,1)',
            backgroundColor: 'rgba(255,159,64,0.2)',
        }
    ]
};

export const monthlyData = {
    labels: ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara'],
    datasets: [
        {
            label: 'Öğrenci Sayısı',
            data: [120, 190, 300, 500, 200, 300, 700, 800, 500, 600, 700, 800],
            borderColor: 'rgba(75,192,192,1)',
            backgroundColor: 'rgba(75,192,192,0.2)',
        },
        {
            label: 'Öğretmen Sayısı',
            data: [20, 30, 200, 500, 100, 400, 800, 900, 600, 700, 800, 900],
            borderColor: 'rgba(153,102,255,1)',
            backgroundColor: 'rgba(153,102,255,0.2)',
        },
        {
            label: 'Okul Sayısı',
            data: [30, 100, 130, 150, 220, 300, 450, 500, 400, 300, 500, 600],
            borderColor: 'rgba(255,159,64,1)',
            backgroundColor: 'rgba(255,159,64,0.2)',
        }
    ]
};

export const yearlyData = {
    labels: ['2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023'],
    datasets: [
        {
            label: 'Öğrenci Sayısı',
            data: [1000, 1200, 1300, 1500, 1700, 1900, 2000, 2200, 2400, 2600, 2800, 3000, 3200],
            borderColor: 'rgba(75,192,192,1)',
            backgroundColor: 'rgba(75,192,192,0.2)',
        },
        {
            label: 'Öğretmen Sayısı',
            data: [200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400],
            borderColor: 'rgba(153,102,255,1)',
            backgroundColor: 'rgba(153,102,255,0.2)',
        },
        {
            label: 'Okul Sayısı',
            data: [50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650],
            borderColor: 'rgba(255,159,64,1)',
            backgroundColor: 'rgba(255,159,64,0.2)',
        }
    ]
};
