import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import turkce from "../../../assets/images/turkcebg.png";
import { MdPlayCircleFilled } from "react-icons/md";
import bg from "../../../assets/images/aaa.png";

import { Grid } from '@mui/material';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'videojs-http-source-selector';
import '@videojs/themes/dist/forest/index.css';

import { CiCalculator2 } from "react-icons/ci";
import chattbot from "../../../assets/images/chat-bot.png"
import { IoSendSharp } from "react-icons/io5";
import VocabularyComponent from './VocabularyComponent.jsx';
import 'videojs-thumbnails'; // Thumbnail eklentisi

import "../../../assets/styles/video.css"
import { Container } from 'react-bootstrap';

const Video = ({ contents, selectedLesson, selectedTopic, selectedUnit }) => {
  const [playingVideoUrl, setPlayingVideoUrl] = useState(null); // Video URL'si için state
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);

  const [showDropdown, setShowDropdown] = useState(false);
  const containerRef = useRef(null);

  const mathematicalSymbols = ['+', '-', '×', '÷', '=', '<', '>', '≤', '≥', '√', 'π', '^',];

  const addSymbolToInput = (symbol) => {
    setInput(input + symbol);
    setShowDropdown(false); // Sembol seçildikten sonra dropdown'ı kapat
  };

  const handleCardClick = (content) => {
    setPlayingVideoUrl(`https://content.robark.com.tr/api/${content.video_url}`); // Card'a tıklandığında video URL'sini ayarla
  };



  const renderContent = (selectedLesson, selectedTopic, content) => {
    switch (selectedLesson) {
      case "Yabancı Dil":
        return (
          <CenteredContent>
            <VocabularyComponent selectedUnit={selectedUnit} />
          </CenteredContent>
        );
      case "Türkçe":
        return (
          <CenteredContent>
            <Styledh6>{selectedTopic}</Styledh6>
            <p>{content.content_text}</p>
          </CenteredContent>
        );
      default:
        return null;
    }
  };

  const sendMessage = async () => {
    if (input.trim() === '') return;

    // Kullanıcı mesajını ekleyin
    const userMessage = { content: input, role: 'user' };
    setMessages(prevMessages => {
      const updatedMessages = [...prevMessages, userMessage];

      // Mesajları 5 geçmişe göre sınırlama
      if (updatedMessages.length > 10) {
        return updatedMessages.slice(updatedMessages.length - 10);
      }
      return updatedMessages;
    });
    setInput('');

    try {
      const instructionsMessages = [
        {
          role: 'system', content: `Sen bir ilköğretim ve ortaöğretim öğretmenisin.
                 Sadece fen, sosyal bilgiler, matematik, Türkçe, İngilizce ve din kültürü dersleriyle ilgili sorulara 
                 cevap verebilirsin. Dersler dışında bir soru sorulduğunda, "Bu konuda yeterli bilgim yok." cevabını vereceksin.
                  Ders dışı hiçbir konuda sorulara cevap vermiyorsun. Öğrencilerin sorularını cevaplarken nazik,
                   sabırlı ve anlaşılır bir dil kullanarak, yaş seviyelerine uygun basit ve açıklayıcı cevaplar ver.
                    Eğer öğrenci küfür veya hakaret içerikli bir soru sorarsa, nazikçe uyar ve bu tür davranışların uygun olmadığını belirt.
                     Dizi, film veya oyuncularla ilgili sorular sorulduğunda, "Bu konuda bilgim yok." cevabını ver. 
                     Tüm sorulara yanıt verirken, Türk milletinin değerleri ve bakış açısına uygun şekilde hareket et.
                    `
        },
      ];

      const response = await fetch('https://api.deepinfra.com/v1/openai/chat/completions', {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer 1phCuVuBPrOG2PdX4sQZJGut2asn0p15'
        },
        body: JSON.stringify({
          model: "meta-llama/Meta-Llama-3.1-70B-Instruct",
          messages: [
            ...instructionsMessages,
            ...messages.slice(-10),  // Son 4 mesajı al
            userMessage
          ],
          temperature: 0.7,
          stream: true,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let accumulatedText = '';
      let assistantMessage = { content: '', role: 'assistant' };  // Rolü 'assistant' olarak güncelle

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const text = decoder.decode(value, { stream: true });
        const lines = text.split('\n');

        for (const line of lines) {
          if (line.startsWith('data: ')) {
            try {
              const data = JSON.parse(line.substring(6));
              if (data.choices && data.choices[0]) {
                accumulatedText += data.choices[0].delta?.content || '';
                assistantMessage.content = accumulatedText;

                setMessages(prevMessages => {
                  const lastMessages = [...prevMessages];
                  if (lastMessages.length > 0 && lastMessages[lastMessages.length - 1].role === 'assistant') {
                    lastMessages[lastMessages.length - 1] = { ...lastMessages[lastMessages.length - 1], content: accumulatedText };
                  } else {
                    lastMessages.push(assistantMessage);
                  }

                  // Mesajları 5 geçmişe göre sınırlama
                  if (lastMessages.length > 10) {
                    return lastMessages.slice(lastMessages.length - 10);
                  }
                  return lastMessages;
                });

                if (data.choices[0].finish_reason === 'stop') {
                  return; // Stop processing further
                }

                // Yavaşlatma işlemi
                await new Promise(resolve => setTimeout(resolve, 50)); // 50ms bekle
              }
            } catch (e) {
              console.error('Error parsing JSON:', e);
            }
          }
        }
      }
    } catch (error) {
      console.error('Error:', error);
      setMessages(prevMessages => [
        ...prevMessages,
        { content: 'Bir hata oluştu. Lütfen tekrar deneyin.', role: 'assistant' }
      ]);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {

    if (videoRef.current && !playerRef.current) {
      playerRef.current = videojs(videoRef.current, {
        controls: true,
        responsive: true,
        fluid: true,
        sources: [
          {
            src: playingVideoUrl,
            type: 'application/x-mpegURL', // m3u8 dosya türü
          },
        ],


      });
    }



    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, [playingVideoUrl]);

  return (
    <div style={{ width: "100%", }}>

      {playingVideoUrl ? (
        <div data-vjs-player style={{ display: "flex", gap: "10px", width: "100%", height: "80vh" }}>
          <VideoPlayer>
            <video ref={videoRef} className="video-js vjs-big-play-centered vjs-luxmty vjs-16-9 vjs-show-big-play-button-on-pause" style={{ width: "100%", height: "100%" }} />
          </VideoPlayer>
          <Chatbot fluid style={{ display: "block", position: "relative", }}>

            <Containerchat ref={containerRef}>
              {messages.length > 0 ? (
                <MessageContainer>
                  {messages.map((message, index) => (
                    <Message key={index} role={message.role}>
                      {console.log(message.role)}
                      {message.role === 'assistant' && (
                        <IconWrapper>
                          <img src={chattbot} />
                        </IconWrapper>
                      )}
                      <MessageBox role={message.role}>
                        {message.content}
                      </MessageBox>
                    </Message>
                  ))}
                </MessageContainer>
              ) : (
<div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
  <div style={{ flex: 1 }} /> {/* Üst boşluk için */}
  <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", gap: "5px", alignItems: "flex-end" }}>
    <ExamplePrompt>Konuyu özetler misin?</ExamplePrompt>
    <ExamplePrompt>Konunun önemli noktaları nelerdir?</ExamplePrompt>
    <ExamplePrompt>Anlamadığım bir yer var, detaylı anlatır mısın?</ExamplePrompt>
  </div>
</div>


              )}
            </Containerchat>


            <div className='flex  items-center  gap-1  w-full absolute bottom-0 left-0' style={{ margin: "10px" }}>
              <MessageInput
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Merak ettiklerini sorabilirsin"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    sendMessage();
                  }
                }}

              />                <SymbolDropdown show={showDropdown}>
                {mathematicalSymbols.map((symbol, index) => (
                  <SymbolButton key={index} onClick={() => addSymbolToInput(symbol)}>
                    {symbol}
                  </SymbolButton>
                ))}
              </SymbolDropdown>
              <SendButton onClick={sendMessage}>
                <IoSendSharp style={{ width: "25px", height: "25px", color: "#606a7a", display: "inline", cursor: "pointer" }} />
              </SendButton>

              <IconWrapper2>
                <CiCalculator2 style={{ width: "30px", height: "30px", color: "#606a7a", cursor: "pointer" }} onClick={() => setShowDropdown(!showDropdown)} />
              </IconWrapper2>



            </div>
          </Chatbot>
        </div>
      ) : (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          {/* <div
            style={{
              width: "100%",
              height: "60px",
              borderRadius: "10px",
              backgroundColor: "#fbfbfb",
              border: "1px solid #ececec",
              display: "flex",
              alignItems: "center",
              paddingLeft: "30px",
            }}
          >
            <p style={{
              fontFamily: "Poppins", fontWeight: "600", fontSize: "20px", marginBottom: "0px",
            }}>
              {selectedTopic}
            </p>
          </div> */}

          <Grid container > {/* Grid kapsayıcı olmalı */}
            {contents.map((content) => (
              <Grid item xs={12} sm={6} md={3} key={content.id}> {/* Her öğe burada ayarlandı */}
                {content.type === "1" ? (
                  <Card onClick={() => handleCardClick(content)}>
                    <Content>
                      <div style={{ position: "absolute", display: "flex", bottom: "5px", right: "5px", gap: "15px" }}>
                        <Prev2>{selectedLesson}</Prev2>
                        <Prev>{content.video_duration}</Prev>
                      </div>
                      <img src={`https://content.robark.com.tr/${content.image_url}`} style={{ width: "100%", height: "170px", objectFit: "cover", borderTopLeftRadius: "20px", borderTopRightRadius: "20px" }} />
                      <PlayIcon className="play-icon" />
                    </Content>
                    <CardText>
                      <Text>{content.description}</Text>
                      <StyledWrapper />
                    </CardText>
                  </Card>
                ) : content.type === "2" ? (
                  <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    {renderContent(selectedLesson, selectedTopic, content)}
                  </div>
                ) : null}
              </Grid>
            ))}
          </Grid>
        </div>
      )}
    </div>

  );
}


export default Video;


const MessageInput = styled.input`
    width: 85%;
    height: 55px;
    padding-left: 65px;
    outline: none;
    position: relative;

    border-radius: 20px;
    border:1px solid #ededed;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: rgb(90, 97, 109);
    
`;
const ExamplePrompt = styled.button`
  padding: 7px 15px;
  font-size: 15px;
  border-radius: 15px;
  font-family: Onest;
  background-color: #4c5565;
  color: white;
  width: auto; // İçeriğe göre genişliği ayarlar
  display: inline-block;
`;

const Containerchat = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90%;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    padding: 10px;
    overflow-y: auto;
`;
const Styledh6 = styled.h6`
  font-weight: 600;
  font-size: 23px;
  font-family: 'Rubik', sans-serif;
  color: #4c5565;
  display: inline;
  text-decoration: none;
`;

const CenteredContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const SymbolDropdown = styled.div`
  position: absolute;
  bottom: 60px;
  left: 10%;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  color: #767676;
  display: ${(props) => (props.show ? 'block' : 'none')};
  z-index: 100;
  padding: 10px;
`;
const SendButton = styled.button`
    width: 40px;
    height: 40px;
    border: none;
    margin-left: 5px;
    border-radius: 25px;
    cursor: pointer;
`;

const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width:100%;
    
`;

const SymbolButton = styled.button`
  background: none;
  border: none;
  font-size: 20px;
  padding: 5px;
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;

  &:hover {
    background-color: #eeee;
}
`;
const Message = styled.div`
    display: flex;
    justify-content: ${props => props.role === 'user' ? 'flex-end' : 'flex-start'};
    min-width: 95%;
`;

const MessageBox = styled.div`
    max-width: 60%;
    background-color: ${props => props.role === 'user' ? '#4c5565' : '#ffffff'};
    color:  ${props => props.role === 'user' ? '#ffff' : '#4c5565'} !important;
    border-radius: 30px;
    padding: 20px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    word-break: break-word;
    font-size: 14px;
`;

const VideoPlayer = styled.div`
  width: 75%;
  height: 100%;
  display:flex;
  border-radius: 15px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
`;

const Chatbot = styled(Container)`
  height: 100%;
  width: 40%;
  background-image: linear-gradient(to bottom, #f5f7f9 0%, white 100%);
  background-image: url(${bg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;   
  border-radius: 20px;
  border: 1px solid #efefef;
  padding-bottom: 10px;
  

`;

const StyledWrapper = styled.div`
  transition: transform 0.6s;
  &:hover {
    transform: scale(1.1);
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  height: 250px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 20px;
  width: 270px;
  position: relative;
  overflow: hidden;

  &:hover .play-icon {
    opacity: 0.5;
    visibility: visible;
    transform: translateY(0);
  }
`;
const IconWrapper2 = styled.div`
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconWrapper = styled.div`
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    margin-bottom: 20px;
`;

const PlayIcon = styled(MdPlayCircleFilled)`
  position: absolute;
  top: 30%;
  left: 40%;
  width: 60px;
  height: 60px;
  color: #000000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, transform 0.5s ease;
  transform: translateY(-10px);
`;

const Content = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 170px;
  width: 100%;
`;

const Prev = styled.div`
  background-color: #00000078;
  color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 25px;
  font-size: 13px;
`;

const Prev2 = styled.div`
  background-color: #00000078;
  color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 13px;
`;

const Text = styled.h6`
  font-family: Open Sans;
  font-weight: 600;
  color: #5c5c5c;
  font-family: 15px;
`;

const CardText = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 15px;
  padding-left: 15px;
  gap: 20px;
  align-items: center;
  background-color: #CDDCDC;
  background-image: radial-gradient(73% 147%, #EADFDF 59%, #ECE2DF 100%), radial-gradient(91% 146%, rgba(255,255,255,0.50) 47%, rgba(0,0,0,0.50) 100%);
  background-blend-mode: screen;      
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`;
