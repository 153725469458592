import React, { useState, useEffect } from 'react';
import { Container, Typography, MenuItem, Select, FormControl, InputLabel, Button, TextField, Box } from '@mui/material';

const EditStudent = () => {
  const [classes, setClasses] = useState(['1', '2', '3', '4', '5', '6', '7', '8']);
  const [sections, setSections] = useState(['A', 'B', 'C', 'D', 'E']);
  const [students, setStudents] = useState([]);
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedSection, setSelectedSection] = useState('');
  const [selectedStudent, setSelectedStudent] = useState(null);

  useEffect(() => {
    // Öğrenci bilgilerini API'den veya localStorage'dan çekebilirsiniz
    if (selectedClass && selectedSection) {
      const fetchedStudents = [
        { id: '1', name: 'John Doe', surname: 'Doe', dob: '2005-05-05', class: '5A', studentNumber: '202463', email: 'john.doe@example.com' },
        { id: '2', name: 'Jane Doe', surname: 'Doe', dob: '2006-06-06', class: '6B', studentNumber: '202464', email: 'jane.doe@example.com' },
        // Diğer öğrenciler
      ];
      setStudents(fetchedStudents);
    }
  }, [selectedClass, selectedSection]);

  const handleClassChange = (event) => {
    setSelectedClass(event.target.value);
    setSelectedSection('');
    setStudents([]);
    setSelectedStudent(null);
  };

  const handleSectionChange = (event) => {
    setSelectedSection(event.target.value);
    setStudents([]);
    setSelectedStudent(null);
  };

  const handleStudentChange = (event) => {
    const studentId = event.target.value;
    const student = students.find(student => student.id === studentId);
    setSelectedStudent(student);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedStudent((prevStudent) => ({
      ...prevStudent,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Burada güncellenmiş öğrenci bilgilerini API'ye gönderin
    console.log('Updated student data:', selectedStudent);
  };

  const handleDelete = () => {
    // Burada silme işlemini gerçekleştirin (API çağrısı yapabilirsiniz)
    console.log('Deleted student:', selectedStudent);
    setStudents(students.filter(student => student.id !== selectedStudent.id));
    setSelectedStudent(null);
  };

  return (
    <Container maxWidth="sm">
      <Box mt={4}>
        <Typography variant="h4" align="center" gutterBottom>
          Öğrenci Bilgilerini Düzenle
        </Typography>
      </Box>

      <FormControl fullWidth margin="normal">
        <InputLabel>Sınıf</InputLabel>
        <Select value={selectedClass} onChange={handleClassChange} displayEmpty>
          <MenuItem value="" disabled>
            Sınıf Seçin
          </MenuItem>
          {classes.map((classItem) => (
            <MenuItem key={classItem} value={classItem}>
              {classItem}. Sınıf
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {selectedClass && (
        <FormControl fullWidth margin="normal">
          <InputLabel>Şube</InputLabel>
          <Select value={selectedSection} onChange={handleSectionChange} displayEmpty>
            <MenuItem value="" disabled>
              Şube Seçin
            </MenuItem>
            {sections.map((section) => (
              <MenuItem key={section} value={section}>
                {section} Şubesi
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {selectedSection && (
        <FormControl fullWidth margin="normal">
          <InputLabel>Öğrenci</InputLabel>
          <Select value={selectedStudent ? selectedStudent.id : ''} onChange={handleStudentChange} displayEmpty>
            <MenuItem value="" disabled>
              Öğrenci Seçin
            </MenuItem>
            {students.map((student) => (
              <MenuItem key={student.id} value={student.id}>
                {student.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {selectedStudent && (
        <form onSubmit={handleSubmit}>
          <TextField
            label="Ad"
            name="name"
            value={selectedStudent.name}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Soyad"
            name="surname"
            value={selectedStudent.surname}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Doğum Tarihi"
            name="dob"
            value={selectedStudent.dob}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            type="date"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Sınıf</InputLabel>
            <Select
              label="Sınıf"
              name="class"
              value={selectedStudent.class}
              onChange={handleInputChange}
              displayEmpty
            >
              <MenuItem value="" disabled>
                Sınıf Seçin
              </MenuItem>
              {classes.map((classItem) => (
                <MenuItem key={classItem} value={classItem}>
                  {classItem}. Sınıf
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Öğrenci Numarası"
            name="studentNumber"
            value={selectedStudent.studentNumber}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Mail Adresi"
            name="email"
            value={selectedStudent.email}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            type="email"
          />
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Güncelle
          </Button>
          <Button
            onClick={handleDelete}
            variant="contained"
            color="secondary"
            fullWidth
            style={{ marginTop: '10px', backgroundColor: 'red' }}
          >
            Sil
          </Button>
        </form>
      )}
    </Container>
  );
};

export default EditStudent;
