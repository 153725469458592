import React from 'react';
import styled from 'styled-components';

// Styled Components
export const QuestionContainer = styled.div`
  width: 80%;
  height: 80%;
`;

export const Circle = styled.span`
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border:  ${({ selected }) => (selected ? '2px solid #ea517c' : '2px solid rgb(76, 74, 112)')};
  text-align: center;
  line-height: 28px;
  font-weight: 600;
  color: ${({ selected }) => (selected ? '#ea517c' : 'rgb(76, 74, 112)')};
  margin-right: 10px;
  transition: all 0.3s ease;

`;
export const Question = styled.h6`
  font-family: Poppins;
  font-weight: 500;
  color: rgb(76, 74, 112);
  font-size: 19px;
  margin-bottom: 30px;
`;

export const OptionButton = styled.button`
  width: 100%;
  padding: 15px;
  margin: 10px 0;
  border-radius: 20px;
  border:  ${({ selected }) => (selected ? '2px solid #ea517c' : '2px solid rgb(76, 74, 112)')};
  background-color: white;
  color: ${({ selected }) => (selected ? '#ea517c' : 'rgb(76, 74, 112)')};
  font-weight: bold;
  text-align: left;
  cursor: pointer;
  font-family: "Varela";
  font-size: 16px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;

  &:hover {
    color: #ea517c;
    border-color: #ea517c;
    background-color: #ffffff;
  }

  &:hover ${Circle} {
  color: #ea517c;
    border:2px solid #ea517c;
  }

`;



const CoktanSecmeliTest = ({ currentQuestion, selectedAnswers, handleAnswerChange }) => (
  <QuestionContainer key={currentQuestion.id}>
    <Question>{currentQuestion.question}</Question>
    {currentQuestion.question_options.map((option) => (
      <OptionButton
        key={option.id}
        selected={selectedAnswers[currentQuestion.id] === option.option.id}
        onClick={() => handleAnswerChange(currentQuestion.id, option.option.id)}
      >
        <Circle selected={selectedAnswers[currentQuestion.id] === option.option.id}>
          {option.option.text}
        </Circle>
        {option.text}
      </OptionButton>
    ))}
  </QuestionContainer>
);

export default CoktanSecmeliTest;
