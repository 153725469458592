import React, { useState } from 'react';
import styled from 'styled-components';
import { RiDashboardFill } from "react-icons/ri";
import { PiVideoFill } from "react-icons/pi";
import { HiDocumentChartBar } from "react-icons/hi2";
import { FaFolderOpen } from "react-icons/fa6";
import { BsChatRightQuoteFill } from "react-icons/bs";
import { MdMapsHomeWork } from "react-icons/md";
import { HiMiniUser } from "react-icons/hi2";
import { IoLogOut } from "react-icons/io5";
import { FaUserGraduate } from "react-icons/fa";
import { useSelector } from 'react-redux';
import { FaCaretRight } from "react-icons/fa6";
import { FaCaretLeft } from "react-icons/fa6";
import { RiRobot3Fill } from "react-icons/ri";

const Sidebar = ({ selectedItem, setSelectedItem, }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };
  const handleListItemClick = (component) => {
    setSelectedItem(component);
  };

  
  console.log(selectedItem)
  const userInfo = useSelector((state) => state.user.userInfo);
  console.log("userinfosu ", userInfo)
  return (
    <SidebarContainer isCollapsed={isCollapsed}>
            <div className='flex justify-center mb-5'>
        <RiRobot3Fill style={{ width: '34px', height: '34px', marginLeft: '5px', color: "#384152", backgroundColor: 'transparent', display: 'inline', }} />
        {!isCollapsed && (
        <div className="mx-3" style={{ zIndex: '999', fontFamily: "'Poppins', sans-serif", fontWeight: 'bold', fontSize: '17px', position: 'relative', lineHeight: '90%' }}>
          <span style={{ color: '#384152', position: 'absolute', fontSize: "19px", top: '0', left: '0' }}>
            rob
          </span> <span style={{ color: '#626977', position: 'absolute', fontSize: "19px", top: '0', left: '34px' }}>
            ot
          </span>
          <br />
          <span style={{ color: '#384152', fontSize: "19px" }}>ark</span>
          <span style={{ color: '#626977', fontSize: "19px" }}>adaşım</span>
        </div>
          )}
      </div>
   
    
      <Menus>
        <Btn onClick={toggleSidebar}>
          {isCollapsed ? <FaCaretRight style={{ width: "20px", height: "20px", color: "#384152" }} /> : <FaCaretLeft style={{ width: "20px", height: "20px", color: "#384152" }} />}
        </Btn>
        
        {!isCollapsed && <p style={{ color: "#384152", fontFamily: "Poppins", fontSize: "12px" }}>Ana Menü</p>}
        <MenuContainer>
          {menuItems.map((item, index) => (
            <Menu key={index} isCollapsed={isCollapsed} selected={selectedItem === item.item} onClick={() => handleListItemClick(item.item)}>
              <MenuImg>
                {item.imgSrc}
              </MenuImg>
              <MenuName isCollapsed={isCollapsed}>{item.name}</MenuName>
            </Menu>
          ))}
        </MenuContainer>

        {!isCollapsed && <p style={{ color: "#384152", fontFamily: "Poppins", fontSize: "12px" }}>Öğrenciler</p>}
        <MenuContainer>
          {studentItems.map((item, index) => (
            <Menu key={index} isCollapsed={isCollapsed} selected={selectedItem === item.item} onClick={() => handleListItemClick(item.item)}>
              <MenuImg>
                {item.imgSrc}
              </MenuImg>
              <MenuName isCollapsed={isCollapsed}>{item.name}</MenuName>
            </Menu>
          ))}
        </MenuContainer>

        {!isCollapsed && <p style={{ color: "#384152", fontFamily: "Poppins", fontSize: "12px" }}>Öğretmenler</p>}
        <MenuContainer>
          {teacherItems.map((item, index) => (
            <Menu key={index} isCollapsed={isCollapsed} selected={selectedItem === item.item} onClick={() => handleListItemClick(item.item)}>
              <MenuImg>
                {item.imgSrc}
              </MenuImg>
              <MenuName isCollapsed={isCollapsed}>{item.name}</MenuName>
            </Menu>
          ))}
        </MenuContainer>

        {!isCollapsed && <p style={{ color: "#384152", fontFamily: "Poppins", fontSize: "12px" }}>Sınıflar</p>}
        <MenuContainer>
          {classItems.map((item, index) => (
            <Menu key={index} isCollapsed={isCollapsed} selected={selectedItem === item.item} onClick={() => handleListItemClick(item.item)}>
              <MenuImg>
                {item.imgSrc}
              </MenuImg>
              <MenuName isCollapsed={isCollapsed}>{item.name}</MenuName>
            </Menu>
          ))}
        </MenuContainer>

        {!isCollapsed && <p style={{ color: "#384152", fontFamily: "Poppins", fontSize: "12px" }}>Diğerleri</p>}
        <MenuContainer service>
          {serviceItems.map((item, index) => (
            <Menu key={index} isCollapsed={isCollapsed} selected={selectedItem === item.item} onClick={() => handleListItemClick(item.item)}>
              <MenuImg>
                {item.imgSrc}
              </MenuImg>
              <MenuName isCollapsed={isCollapsed}>{item.name}</MenuName>
            </Menu>
          ))}
        </MenuContainer>
      </Menus>
    </SidebarContainer>
  );
};

const Title = styled.h6`
  font-family: "Poppins";
  font-weight: 600;
  color: #ebebeb;
  text-align: center;
  font-size: 18px;
  padding: 18px;
  white-space: nowrap;
`;

const SidebarContainer = styled.div`
  width: ${props => props.isCollapsed ? '100px' : '290px'};
  backdrop-filter: blur(20px);
  display: flex;
  flex-direction: column;
  transition: width 0.9s;
  border-radius: 30px;
  border: 1px solid #ddd;
  overflow-y:scroll;
  scrollbar-width: none; /* Firefox için */
  padding-top: 30px;
  background-color:#fcfcfc;
  ::-webkit-scrollbar:{
    display: none;
  };
`;

const Header = styled.div`
  display: flex;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
`;

const Profile = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 70%;
`;

const ProfilePic = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProfileName = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Btn = styled.div`
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  cursor: pointer;
  right: 5%;
`;

const Menus = styled.div`
padding: 20px;
  display: flex;
  flex-direction: column;
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  
`;

const MenuImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.selected ? 'white' : '#384152'};
`;

const Menu = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  border-radius: 15px;
  cursor: pointer;
  padding: 10px 15px;
  color: ${props => props.selected ? 'white' : '#384152'};
  background-image: ${props => props.selected ? 'linear-gradient(120deg, #ff867b 0%, #e182f4 100%)' : 'transparent'};
  margin: 1px;
  align-items: center;
  justify-content: ${props => props.isCollapsed ? 'center' : 'flex-start'};
  transition: background-color 0.3s ease;

  &:hover {
    background-image: linear-gradient(120deg, #ff867b 0%, #e182f4 100%);
    color: white;

    ${MenuImg} {
      color: white;
    }
  }

  ${MenuImg} {
    color: ${props => props.selected ? 'white' : '#384152'};
  }
`;


const MenuName = styled.div`
margin-left: 20px;
  width: 80%;
  font-size: 14px;
  display: ${props => props.isCollapsed ? 'none' : 'flex'};
  align-items: center;

`;

const menuItems = [
  { name: 'Anasayfam', item: 'anasayfa', imgSrc: <RiDashboardFill style={{ width: "17px", height: "17px" }} />, altText: 'dashboard-layout' },
];


const studentItems = [
  { name: 'Öğrenci Ekle', item: 'ogrenciekle', imgSrc: <PiVideoFill style={{ width: "17px", height: "17px", }} />, altText: 'task' },
  { name: 'Öğrenci Düzenle', item: 'ogrenciduzenle', imgSrc: <HiDocumentChartBar style={{ width: "17px", height: "17px", }} />, altText: 'calendar' },
  { name: 'Performanslar', item: 'performanslar', imgSrc: <FaFolderOpen style={{ width: "17px", height: "17px", }} />, altText: 'new-post' },
];

const teacherItems = [
  { name: 'Öğretmen Ekle', item: 'ogretmenekle', imgSrc: <PiVideoFill style={{ width: "17px", height: "17px", }} />, altText: 'task' },
  { name: 'Öğretmen Sil', item: 'ogretmensil', imgSrc: <HiDocumentChartBar style={{ width: "17px", height: "17px", }} />, altText: 'calendar' },
];

const classItems = [
  { name: 'Sınıf Ekle', item: 'sınıfekle', imgSrc: <PiVideoFill style={{ width: "17px", height: "17px", }} />, altText: 'task' },
  { name: 'Sınıf Düzenle', item: 'sınıfduzenle', imgSrc: <HiDocumentChartBar style={{ width: "17px", height: "17px", }} />, altText: 'calendar' },
];
const serviceItems = [
  { name: 'Profilim', item: 'profilim',imgSrc: <HiMiniUser style={{ width: "20px", height: "20px", }} />, altText: 'laptop-metrics--v2' },
  { name: 'Çıkış Yap',item: 'cikis', imgSrc: <IoLogOut style={{ width: "20px", height: "20px"}} />, altText: 'web--v1' },
];

export default Sidebar;
