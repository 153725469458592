import React, { useDeferredValue } from 'react';
import Navbar from '../components/Navbar';
import { useDispatch, useSelector } from 'react-redux';
import { clearCart, removeItem } from '../redux/cartSlice';
import styled from 'styled-components';
import lottie from "lottie-web";

const BasketLi = styled.li`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 15px;
  height: 150px;
  margin-bottom: 10px;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 30px;
  width: 100%;
  max-width: 70%;
  justify-content: space-between;
  position: relative;
`;

const PriceButton = styled.div`
  display: flex;
  justify-content: center;
  height: 50px;
  gap: 20px;
  padding: 9px;
  font-family: 'Raleway', sans-serif;
  border-radius: 12px;
  font-size: 15px;
  color: rgb(95, 132, 162);
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const Basket = () => {

    const dispatch = useDispatch();
    const cartItems = useSelector((state) => state.cart.items);

    const handleClearCart = () => {
        dispatch(clearCart());
    };

    const handleRemoveFromCart = (id) => {
        dispatch(removeItem(id));
    };

    const handleDecrement = (index) => {
        // Ürün sayısını azaltan fonksiyon
    };

    const handleIncrement = (index) => {
        // Ürün sayısını artıran fonksiyon
    };

    return (
        <div>
            <Navbar />
            <Container>
            <div style={{ textAlign: 'center', width: '100%' }}>
                <h2>Sepettekiler</h2>
                {cartItems.length === 0 ? (
                    <p>Sepetiniz boş</p>
                ) : (
                    <ul style={{ listStyleType: 'none', padding: 0, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {cartItems.map((item, index) => (
                            <BasketLi key={index}>
                                <img src={item.image} style={{ width: "100px", height: "100px", marginRight: "40px" }} className="card-image" />
                                <h6 style={{ fontFamily: "'Varela Round', sans-serif", fontWeight: "500", fontSize: "18px", color: "rgb(95, 132, 162)" }}>{item.title} </h6>
                                <PriceButton>
                                    <button style={{ backgroundColor: "transparent", fontSize: "23px" }} onClick={() => handleDecrement(index)}>-</button>
                                    <h6 style={{ fontSize: "23px" }}>{item.piece}</h6>
                                    <button onClick={() => handleIncrement(index)} style={{ backgroundColor: "transparent", fontSize: "23px" }}>+</button>
                                </PriceButton>
                                <h6 style={{
                                    float: "right",
                                    fontFamily: "Varela Round",
                                    fontSize: "23px",
                                    fontWeight: "500",
                                    color: "rgb(95, 132, 162)"
                                }}>{item.price}TL</h6>
                                <script src="https://cdn.lordicon.com/lordicon.js"></script>
                                <button onClick={() => handleRemoveFromCart(item.id)}>
                                    {/* <lord-icon
                                        src="https://cdn.lordicon.com/wpyrrmcq.json"
                                        trigger="hover"
                                        colors="primary:#dadada"
                                        style={{ width: "25px", height: "25px", float: "right", position: "absolute", top: "10%", right: "2%" }}
                                    >
                                    </lord-icon> */}
                                </button>
                            </BasketLi>
                        ))}
                    </ul>
                )}
            </div>
            <div className="btnn flex mx-auto mt-3" style={{ textAlign: 'center' }}>
                <button onClick={handleClearCart} className="py-3 px-5 flex gap-2" style={{ backgroundColor: "#d9534f" }}>
                    <span style={{ color: "white", fontSize: "14px" }}>Sepeti Temizle</span>
                </button>
            </div>
            </Container>
        </div>
    );
};

export default Basket;
