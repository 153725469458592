import React, { useState, useEffect, useRef } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import { TbSend2 } from "react-icons/tb";
import chatbot from "../../../assets/images/chatbot.png"
import { CiZoomIn } from "react-icons/ci";
import { CiCircleQuestion } from "react-icons/ci";
import { CiCircleList } from "react-icons/ci";
import chattbot from "../../../assets/images/chat-bot.png"
import { PiMathOperations } from "react-icons/pi";

import NavigationBar from './NavigationBar';
import { useSelector } from 'react-redux';
import bg from "../../../assets/images/bg.svg";


const Search = styled.input`
    width: 300px;
    height: 50px;
    outline: none;
    background-color: #fafafa;
    padding-left: 30px;
    border-radius: 50px;
    font-family: 'Poppins', sans-serif;
    color: #384152;
    border: 2px solid #4d576a;
    &::placeholder {
        color: #384152;
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
    }
`;

const Styledh6 = styled.h6`
    font-weight: 600;
    font-size: 23px;
    font-family: 'Rubik', sans-serif;
    color: #4c5565;
    display: inline;
    text-decoration: none;
`;
const StyledTitle = styled.h6`
    font-size: 20px;
    font-family: Varela;
    color: #4c5565;
    font-weight: 600;
    display: inline;
    text-decoration: none;
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 70vh;
    background-color: #fcfcfc;
    align-items: center;
    justify-content: center;
    border: 1px solid #e6e6e6;
    border-radius: 30px;
    padding: 10px;
    overflow-y: auto;
`;

const MessageInput = styled.input`
    width: calc(100% - 200px);
    height: 55px;
    padding-left: 65px;
    outline: none;
    position: relative;
    background-color: #fcfcfc;
    border-radius: 25px;
    border: 1px solid #ddd;
    font-family: 'Poppins', sans-serif;
    color: rgb(90, 97, 109);
    
`;

const SendButton = styled.button`
    width: 50px;
    height: 50px;
    background-color: #4c5565;
    border: none;
    border-radius: 25px;
    color: white;
    cursor: pointer;
`;

const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width:100%;
    
`;
const Info = styled.div`
   height: 150px;
   display: flex; 
   flex-direction: column;
    justify-content: center;
     align-items: center; 
     color: #5d6573;
    max-width: 280px; 
    border: 1px solid #e0e0e0;
     border-radius: 15px; 
     padding: 5px 20px ;
     font-size: 14px;
     text-align: center ;
     gap: 10px;
`;

const Message = styled.div`
    display: flex;
    justify-content: ${props => props.role === 'user' ? 'flex-end' : 'flex-start'};
    min-width: 95%;
`;

const MessageBox = styled.div`
    max-width: 60%;
    background-color: ${props => props.role === 'user' ? '#4c5565' : '#ffffffdb'};
    color:  ${props => props.role === 'user' ? '#ffff' : '#4c5565'} !important;
    border-radius: 30px;
    padding: 20px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    word-break: break-word;
`;

const IconWrapper = styled.div`
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    margin-bottom: 20px;
`;


const GlobalStyle = createGlobalStyle`
  @keyframes effect {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.2);
    }
  }
`;



const IconWrapper2 = styled.div`
  position: absolute;
  left: 90px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SymbolDropdown = styled.div`
  position: absolute;
  bottom: 60px;
  left: 10%;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  color: #767676;
  display: ${(props) => (props.show ? 'block' : 'none')};
  z-index: 100;
  padding: 10px;
`;

const SymbolButton = styled.button`
  background: none;
  border: none;
  font-size: 20px;
  padding: 5px;
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;

  &:hover {
    background-color: #eeee;
}
`;

const Chatbot = () => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const containerRef = useRef(null);

    const userInfo = useSelector((state) => state.user.userInfo);
    const name = userInfo.student.first_name;

    const [showDropdown, setShowDropdown] = useState(false);

    const mathematicalSymbols = ['+', '-', '×', '÷', '=', '<', '>', '≤', '≥', '√', 'π', '^',];

    const addSymbolToInput = (symbol) => {
        setInput(input + symbol);
        setShowDropdown(false); // Sembol seçildikten sonra dropdown'ı kapat
    };




    const sendMessage = async () => {
        if (input.trim() === '') return;

        // Kullanıcı mesajını ekleyin
        const userMessage = { content: input, role: 'user' };
        setMessages(prevMessages => {
            const updatedMessages = [...prevMessages, userMessage];

            // Mesajları 5 geçmişe göre sınırlama
            if (updatedMessages.length > 10) {
                return updatedMessages.slice(updatedMessages.length - 10);
            }
            return updatedMessages;
        });
        setInput('');

        try {
            const instructionsMessages = [
                {
                    role: 'system', content: `Sen bir ilköğretim ve ortaöğretim öğretmenisin.
                     Sadece fen, sosyal bilgiler, matematik, Türkçe, İngilizce ve din kültürü dersleriyle ilgili sorulara 
                     cevap verebilirsin. Dersler dışında bir soru sorulduğunda, "Bu konuda yeterli bilgim yok." cevabını vereceksin.
                      Ders dışı hiçbir konuda sorulara cevap vermiyorsun. Öğrencilerin sorularını cevaplarken nazik,
                       sabırlı ve anlaşılır bir dil kullanarak, yaş seviyelerine uygun basit ve açıklayıcı cevaplar ver.
                        Eğer öğrenci küfür veya hakaret içerikli bir soru sorarsa, nazikçe uyar ve bu tür davranışların uygun olmadığını belirt.
                         Dizi, film veya oyuncularla ilgili sorular sorulduğunda, "Bu konuda bilgim yok." cevabını ver. 
                         Tüm sorulara yanıt verirken, Türk milletinin değerleri ve bakış açısına uygun şekilde hareket et.
                         ve öğrenciye nadiren adıyla hitap et : ${name}`
                },
            ];

            const response = await fetch('https://api.deepinfra.com/v1/openai/chat/completions', {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer 1phCuVuBPrOG2PdX4sQZJGut2asn0p15'
                },
                body: JSON.stringify({
                    model: "meta-llama/Meta-Llama-3.1-70B-Instruct",
                    messages: [
                        ...instructionsMessages,
                        ...messages.slice(-10),  // Son 4 mesajı al
                        userMessage
                    ],
                    temperature: 0.7,
                    stream: true,
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let accumulatedText = '';
            let assistantMessage = { content: '', role: 'assistant' };  // Rolü 'assistant' olarak güncelle

            while (true) {
                const { done, value } = await reader.read();
                if (done) break;

                const text = decoder.decode(value, { stream: true });
                const lines = text.split('\n');

                for (const line of lines) {
                    if (line.startsWith('data: ')) {
                        try {
                            const data = JSON.parse(line.substring(6));
                            if (data.choices && data.choices[0]) {
                                accumulatedText += data.choices[0].delta?.content || '';
                                assistantMessage.content = accumulatedText;

                                setMessages(prevMessages => {
                                    const lastMessages = [...prevMessages];
                                    if (lastMessages.length > 0 && lastMessages[lastMessages.length - 1].role === 'assistant') {
                                        lastMessages[lastMessages.length - 1] = { ...lastMessages[lastMessages.length - 1], content: accumulatedText };
                                    } else {
                                        lastMessages.push(assistantMessage);
                                    }

                                    // Mesajları 5 geçmişe göre sınırlama
                                    if (lastMessages.length > 10) {
                                        return lastMessages.slice(lastMessages.length - 10);
                                    }
                                    return lastMessages;
                                });

                                if (data.choices[0].finish_reason === 'stop') {
                                    return; // Stop processing further
                                }

                                // Yavaşlatma işlemi
                                await new Promise(resolve => setTimeout(resolve, 50)); // 50ms bekle
                            }
                        } catch (e) {
                            console.error('Error parsing JSON:', e);
                        }
                    }
                }
            }
        } catch (error) {
            console.error('Error:', error);
            setMessages(prevMessages => [
                ...prevMessages,
                { content: 'Bir hata oluştu. Lütfen tekrar deneyin.', role: 'assistant' }
            ]);
        }
    };

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.scrollTop = container.scrollHeight;
        }
    }, [messages]);

    //backgroundImage: "linear-gradient(-225deg, #ebf7f3 0%, #FFE6FA 100%)" 
    return (
        <div style={{ width: "100vw", height: "100vh", backgroundImage: `url(${bg})`, backgroundSize: "cover", backgroundRepeat: "repeat-y", backgroundPosition: "center" }}>
            <NavigationBar item="Chatbot" />

            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                <Container ref={containerRef}>
                    {messages.length > 0 ? (
                        <MessageContainer>
                            {messages.map((message, index) => (
                                <Message key={index} role={message.role}>
                                    {console.log(message.role)}
                                    {message.role === 'assistant' && (
                                        <IconWrapper>
                                            <img src={chattbot} />
                                        </IconWrapper>
                                    )}
                                    <MessageBox role={message.role}>
                                        {message.content}
                                    </MessageBox>
                                </Message>
                            ))}
                        </MessageContainer>
                    ) : (
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "50px", padding: "20px" }} >
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "30px" }} >
                                <img src={chatbot} style={{ width: "100px", height: "100px" }} />
                                <StyledTitle>Derslerle ilgili aklına takılan soruları birlikte keşfedelim.</StyledTitle>
                            </div>

                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "20px" }}>

                                <Info>
                                    <CiZoomIn style={{ width: "35px", height: "35px", color: "4c5565", zIndex: "99", display: "block" }} />
                                    Aklına takılan konuları chatbot’a sorarak açıklama yapmasını isteyebilirsin
                                    <div />
                                </Info>
                                <Info>
                                    <CiCircleQuestion style={{ width: "35px", height: "35px", color: "4c5565", zIndex: "99", display: "block" }} />
                                    Anlamadığın bir dersi chatbot’a danışarak daha iyi öğrenebilirsin
                                    <div />
                                </Info>
                                <Info >
                                    <CiCircleList style={{ width: "35px", height: "35px", color: "4c5565", zIndex: "99", display: "block" }} />
                                    Çözmekte zorlandığın bir soruyu sorup çözüm alabilirsin.
                                    <div />
                                </Info>

                            </div>
                        </div>
                    )}
                </Container>


                <div className='flex items-center justify-center mt-4 gap-2 relative w-full'>




                    <MessageInput
                        type="text"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        placeholder="Merak ettiklerini sorabilirsin"
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                sendMessage();
                            }
                        }}

                    />                <SymbolDropdown show={showDropdown}>
                        {mathematicalSymbols.map((symbol, index) => (
                            <SymbolButton key={index} onClick={() => addSymbolToInput(symbol)}>
                                {symbol}
                            </SymbolButton>
                        ))}
                    </SymbolDropdown>
                    <SendButton onClick={sendMessage}>
                        <TbSend2 style={{ width: "30px", height: "30px", color: "#ffffff", display: "inline", cursor: "pointer" }} />
                    </SendButton>

                    <IconWrapper2>
                        <PiMathOperations style={{ width: "30px", height: "30px", color: "#606a7a", cursor: "pointer" }} onClick={() => setShowDropdown(!showDropdown)} />
                    </IconWrapper2>



                </div>
            </div>
        </div>

    );
}

export default Chatbot;
